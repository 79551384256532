import React, { useEffect, useState } from 'react'
import HotelsImg from '../assets/hotel1.jpg';
import { useNavigate } from 'react-router-dom';
import { createApi } from 'unsplash-js';

const unsplash = createApi({
    accessKey: "faQ0y7crYQbDgscIPv6z6IO7YlCp-HPbquii8wIUsEI"
});

const CitiesCard = ({ item }) => {
    const [image, setImage] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const handleImage = async () => {
            const res = await unsplash.search.getPhotos({
                query: item?.CityName,
                page: 1,
                perPage: 1
            });
            if (res.response.results.length > 0) {
                const imagePath = res.response.results[0]?.urls?.regular;
                setImage(imagePath);
            } else {
                setImage(null);
            }
            // console.log("image response ---->", res);
        };

        if (item?.CityName) {
            handleImage();
        }

    }, [item?.CityName]);

    return (
        <div className="card card-hotel-wrapper border-0 position-relative" onClick={() => navigate('/detail')}>
            <img src={image !== null ? image : HotelsImg} class="card-img-hotel" alt="..." />

            <h6 className="card-text position-absolute text-center city-txt-wrap p-md font-weight-600">{item?.CityName?.replace(/\s*\(.*?\)\s*/g, '')}</h6>
        </div>
    )
}

export default CitiesCard