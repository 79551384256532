import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import RevertIcon from '../../src/assets/arrow-left-right.svg'
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import HotelBar from './SearchBars/HotelBar';

const BookingFilter = () => {
    const [currentTab, setCurrentTab] = useState('hotels');
    const [flightTab, setFlightTab] = useState('oneWay');
    const navigate = useNavigate();
    const { t } = useTranslation();


    return (
        <div className="flight-banner-wrapper">
            <div className="booking-flight-inner">
                <div className='container'>
                    <nav className='tabs-flight'>
                        <div className="nav nav-tabs navbar-tabs-mine border-0 justify-content-center" id="nav-tab" role="tablist">
                            <button className={`nav-link text-white p-md border-0 d-flex align-items-center gap-2 tabs-btn ${currentTab === 'hotels' ? 'active' : ''}`} id="nav-hotel-tab" data-bs-toggle="tab" data-bs-target="#nav-hotel" type="button" role="tab" aria-controls="nav-hotel" aria-selected="true" onClick={() => setCurrentTab('hotels')}>
                                <img src='../images/hotel-icon.svg' alt='' className='d-inline-block' />
                                {t('hotel_tab')}
                            </button>

                            <button className={`nav-link text-white p-md tabs-btn d-flex align-items-center gap-2 border-0 rounded-0 ${currentTab === 'localtours' ? 'active' : ''}`} id="nav-tours-tab" data-bs-toggle="tab" data-bs-target="#nav-tours" type="button" role="tab" aria-controls="nav-tours" aria-selected="false" onClick={() => setCurrentTab('localtours')}>
                                <img src='../images/golf-sharp-icon.svg' alt='' className='d-inline-block' />
                                {t('local_tours')}
                            </button>

                            <button className={`nav-link text-white p-md d-flex align-items-center gap-2 tabs-btn border-0 rounded-0 ${currentTab === 'flights' ? 'active' : ''}`} id="nav-flights-tab" data-bs-toggle="tab" data-bs-target="#nav-flights" type="button" role="tab" aria-controls="nav-flights" aria-selected="false" onClick={() => setCurrentTab('flights')}>
                                <i className="fas fa-plane"></i>{t('flights')}
                            </button>

                            <button className={`nav-link text-white d-flex align-items-center gap-2 p-md tabs-btn border-0 ${currentTab === 'carRent' ? 'active' : ''}`} id="nav-carrent-tab" data-bs-toggle="tab" data-bs-target="#nav-carrent" type="button" role="tab" aria-controls="nav-carrent" aria-selected="false" onClick={() => setCurrentTab('carRent')}>
                                <i className="fas fa-car"></i>{t('car_Rent')}
                            </button>
                        </div>
                    </nav>
                </div>
                <div className='booking-flight-form'>
                    <div className='container'>
                        <div className="tab-content" id="nav-tabContent">
                            {/*=========First Tab========*/}
                            <div className="tab-pane fade text-white show active" id="nav-hotel" role="tabpanel" aria-labelledby="nav-hotel-tab">
                                <div className="booking-tab-form">
                                    <div className="top-form">
                                        <div className="inner-banner-wrapper text-center">
                                            <h1 className='text-white'>{t('heading')}</h1>
                                            <p className='text-white p-lg'>{t('sub_heading')}</p>
                                        </div>
                                    </div>
                                    <HotelBar />
                                </div>
                            </div>

                            {/*=========Second Tab========*/}
                            <div className="tab-pane text-white fade" id="nav-tours" role="tabpanel" aria-labelledby="nav-tours-tab">
                                <div className="booking-tab-form">
                                    <div className="top-form">
                                        <div className="inner-banner-wrapper text-center">
                                            <h1 className='text-white'>{t('heading')}</h1>
                                            <p className='text-white p-lg'>{t('sub_heading')}</p>
                                        </div>
                                    </div>
                                    <form>
                                        <div class="booking-form-grid booking-tour-grid">
                                            <div className="destination-box first-box">
                                                <img src="./images/geo-alt.png" alt='' className='d-inline-block' />
                                                <input type="text" className="form-control" placeholder="Search Destination"></input>
                                            </div>
                                            <div className="destination-box second-box">
                                                {/* <img src="./images/geo-alt.png"/> */}
                                                <input type="date" className="form-control"></input>
                                            </div>
                                            <div className="destination-box search-btn">
                                                <button type="button" className="btn btn-gradient-purple text-white d-flex align-items-center justify-content-center gap-2" onClick={() => navigate('/local-tours-results')}><i class="fas fa-search me-2"></i>Search</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            {/*=========Third Tab========*/}
                            <div className="tab-pane text-white flight-tab-wrapper fade" id="nav-flights" role="tabpanel" aria-labelledby="nav-flights-tab">
                                <div className="top-form">
                                    <div className="inner-banner-wrapper text-center flight-tab-spacer">
                                        <h1 className='text-white'>{t('heading')}</h1>
                                        <p className='text-white p-lg'>{t('sub_heading')}</p>

                                        <ul class="nav nav-tabs border-0 flight-list-ul justify-content-center" id="myTab" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <button className={`nav-link text-white ${flightTab === 'oneWay' ? 'active' : ''}`} id="oneway-tab" data-bs-toggle="tab" data-bs-target="#oneway" type="button" role="tab" aria-controls="oneway" aria-selected="true" onClick={() => setFlightTab('oneWay')}> One Way</button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button className={`nav-link text-white ${flightTab === 'roundWay' ? 'active' : ''}`} id="roundtrip-tab" data-bs-toggle="tab" data-bs-target="#roundtrip" type="button" role="tab" aria-controls="roundtrip" aria-selected="false" onClick={() => setFlightTab('roundWay')}>Round Trip</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="oneway" role="tabpanel" aria-labelledby="oneway-tab">
                                        <div className="booking-tab-form">
                                            <form>
                                                <div className="booking-form-grid flight-oneway-grid">
                                                    <div className="tab-grid-onewy d-flex align-items-center bg-white">
                                                        <div className='destination-box flight-input-one'>
                                                            <img src="./images/geo-alt.png" alt='' />
                                                            <input type="text" className="form-control border-end-0 rounded-end-0 border-0" placeholder="From"></input>
                                                        </div>
                                                        <div className='revert-icon bg-white'>
                                                            <img src={RevertIcon} alt='img not found' />
                                                        </div>
                                                        <div className='destination-box flight-input-two0'>
                                                            {/* <img src="./images/geo-alt.png" alt='' /> */}
                                                            <input type="text" className="form-control border-start-0 rounded-start-0 border-0" placeholder="To"></input>
                                                        </div>
                                                    </div>

                                                    <div className="destination-box second-box marrgin-left marrgin-right">
                                                        <input type="date" className="form-control"></input>
                                                    </div>

                                                    <div className="destination-box  d-flex justify-content-center align-items-center first-box">
                                                        <img src="./images/people-outline.png" alt='' />
                                                        <input type="text" className="form-control" placeholder="2 Adult, 1 Children"></input>
                                                    </div>
                                                    <div className="destination-box search-btn">
                                                        <button type="button" className="btn btn-gradient-purple text-white d-flex align-items-center justify-content-center gap-2" onClick={() => navigate('/flight-results')}><i class="fas fa-search me-2"></i>Search</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="roundtrip" role="tabpanel" aria-labelledby="roundtrip-tab">
                                        <div className="booking-tab-form">
                                            <form>
                                                <div className="booking-form-grid flight-oneway-grid">
                                                    <div className="tab-grid-onewy d-flex align-items-center bg-white">
                                                        <div className='destination-box flight-input-one'>
                                                            <img src="./images/geo-alt.png" alt='' />
                                                            <input type="text" className="form-control border-end-0 rounded-end-0 border-0" placeholder="From"></input>
                                                        </div>
                                                        <div className='revert-icon bg-white'>
                                                            <img src={RevertIcon} alt='img not found' />
                                                        </div>
                                                        <div className='destination-box flight-input-two0'>
                                                            {/* <img src="./images/geo-alt.png" alt='' /> */}
                                                            <input type="text" className="form-control border-start-0 rounded-start-0 border-0" placeholder="To"></input>
                                                        </div>
                                                    </div>

                                                    <div className="destination-box second-box marrgin-left">
                                                        <input type="date" className="form-control"></input>
                                                    </div>

                                                    <div className="destination-box  d-flex justify-content-center align-items-center first-box">
                                                        <img src="./images/people-outline.png" alt='' />
                                                        <input type="text" className="form-control" placeholder="2 Adult, 1 Children"></input>
                                                    </div>
                                                    <div className="destination-box search-btn">
                                                        <button type="button" className="btn btn-gradient-purple text-white d-flex align-items-center justify-content-center gap-2"
                                                            onClick={() => {
                                                                if (flightTab === 'oneWay') {
                                                                    navigate('/flight-results');
                                                                } else if (flightTab === 'roundWay') {
                                                                    navigate('/round-trip-results');
                                                                }
                                                            }}
                                                        >
                                                            <i class="fas fa-search me-2"></i>
                                                            Search
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*=========Fourth Tab========*/}
                            <div className="tab-pane text-white fade" id="nav-carrent" role="tabpanel" aria-labelledby="nav-carrent-tab">
                                <div className="booking-tab-form">
                                    <div className="top-form">
                                        <div className="inner-banner-wrapper text-center">
                                            <h1 className='text-white'>{t('heading')}</h1>
                                            <p className='text-white p-lg'>{t('sub_heading')}</p>
                                        </div>
                                    </div>
                                    <form>
                                        <div class="booking-form-grid booking-fourth-grid">
                                            <div className="destination-box first-box">
                                                <img src="./images/geo-alt.png" alt='' />
                                                <input type="text" className="form-control" placeholder="Pick-up Location"></input>
                                            </div>
                                            <div className="destination-box second-box">

                                                <input type="date" className="form-control"></input>
                                            </div>
                                            <div className="destination-box search-btn">
                                                <button type="button" className="btn btn-gradient-purple text-white d-flex align-items-center justify-content-center gap-2" onClick={() => navigate('/car-results')}><i class="fas fa-search me-2"></i>Search</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default BookingFilter