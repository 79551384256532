import React from 'react'
import RoomOne from '../assets/room1.png'
import GreenCheck from '../assets/green-check.png'
import { Link } from 'react-router-dom'

const RoomsCard = ({ item }) => {
 
    return (
        <div className='realted_inner'>
            <img src={RoomOne} className='img-fluid w-100'/>
            <div className='inner-details-cnt'>
                <h3 className='my-3'>Regular Room {item} - Queen Bed</h3>
                <div className='row'>
                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div className='room-feature'>
                            <ul className='p-0'>
                                <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2' alt='' />2 Adult</li>
                                <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2'alt='' />Free Wi-fi</li>
                                <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2' alt='' />Coffee/tea maker</li>
                                <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2' alt='' />Queen Bed</li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div className='room-feature'>
                            <ul className='p-0'>
                                <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2' alt='' />TV</li>
                                <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2' alt='' />2 Adult</li>
                                <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2' alt='' />Air Condition</li>
                                <li className='p-md'><img src={GreenCheck} className='d-inline-block me-2' alt='' />24 Hour Room Service</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='price-room d-flex align-items-center justify-content-between'>
                    <div className='price-box-bottom-txt'>
                        <p className='d-inline-block size-big me-3 tab-0-content-sp'><b>$35</b><strike>$41</strike></p>
                        <p className='d-inline-block p-md'>1 Night(incl.VAT)</p>
                    </div>
                    <div className=''>
                        <p className='text-danger p-md mb-0'>Only 2 Left</p>
                    </div>
                </div>
                <Link to="/hotel-booking/payment" className='btn btn-gradient-purple'>Add Room</Link>
            </div>
        </div>
    )
}

export default RoomsCard