import React from 'react'
import OffersCard from '../../shared/OffersCard';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';

const Offers = () => {
  const arr = [1, 2, 3, 4];
  const { t } = useTranslation();

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div>
      <div className='offer-card-wrapper'>
        <div className='container'>
          <h2 className='mb-3'>{t('offers')}</h2>
          <div className=''>
            <Slider {...settings}>
              {arr.map((item, index) =>
                <div class="" key={index}>
                  <OffersCard />
                </div>)}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Offers