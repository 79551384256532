import React from 'react'
import BookingIMG from './../../assets/booking-confirm.png'
import BGreenCheck from '../../assets/big-green-check.png'

const BookingConfirm = () => {
    return (
        <div>
            <div className='booking-confirm-wrapper text-center'>
                <div className='container'>
                    <div className='row justify-content-center mt-5'>
                        <div className='col-lg-8 col-md-10 col-sm-12'>
                            <div className=''>
                                <img src={BookingIMG} alt='' className='mx-auto pb-4' />
                                <h2 className='d-flex align-items-center justify-content-center font-size-heading'>Your Booking is Confirmed <img src={BGreenCheck} className='ms-3' /></h2>
                                <p className='mb-0 font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                <div className='booking-btns-wrapper d-flex align-items-center gap-2 justify-content-center'>
                                    <a href='#' className='btn btn-gradient-purple text-white d-inline-block w-auto booking-btns'>Home</a>
                                    <a href='#' className='btn btn-gradient-purple text-white d-inline-block w-auto booking-btns'>My Orders</a>
                                </div>
                                <div className='price-box-room confirm-price-box'>
                                    <h5 className='text-purple p-large mb-0 text-start'>Payment Details</h5>
                                    <div className='price-line-dotted align-items-end'>
                                        <div className=''>
                                            <p className='p-md mb-0 text-start'>Regular Room - Queen Bed</p>
                                            <p className='p-sm mb-0 text-start'>1 Room</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme font-weight-500'><strike className='p-sm me-2 text-grey-light font-weight-normal'>$41</strike>$35</p>
                                        </div>
                                    </div>

                                    <div className='price-line-dotted align-items-end'>
                                        <div className=''>
                                            <p className='p-md mb-0 text-start'>Regular Room - Queen Bed</p>
                                            <p className='p-sm mb-0 text-start'>1 Room</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme font-weight-500'><strike className='p-sm me-2 text-grey-light font-weight-normal'>$41</strike>$35</p>
                                        </div>
                                    </div>

                                    <div className='price-line-dotted price-line-plane align-items-end'>
                                        <div className=''>
                                            <p className='p-md mb-0 text-start'>Regular Room - Queen Bed</p>
                                            <p className='p-sm mb-0 text-start'>1 Room</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme font-weight-500'><strike className='p-sm me-2 text-grey-light font-weight-normal'>$41</strike>$35</p>
                                        </div>
                                    </div>

                                    <div className='price-line-dotted align-items-center border-0 pb-0 pt-3 last-payment'>
                                        <div className=''>
                                            <p className='p-large mb-0 font-weight-600 text-color-theme text-start'>Total Payment</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-large mb-0 font-weight-600 text-color-theme last-txt'>$137</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookingConfirm;