import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import PhoneNumber from '../shared/PhoneNumber';
import { getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input';
import { get_vender_id, user_send_otp } from '../reduxStore/User/userActions';
import { connect, useDispatch } from 'react-redux';
import { useToast } from '@chakra-ui/react';

const EnterEmailorPhone = ({ user, vendor_id }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const toast = useToast();
    const [formdata, setFormdata] = useState({
        email: '',
        phone_number: ''
    });
    const [country, setCountry] = useState('US');
    const [errors, setErrors] = useState({
        email: '',
        phone_number: '',
    });
    const dispatch = useDispatch();
    const [verifyData, setVerifyData] = useState('');
    const exptest = /^(?!.*\.\.)(?!.*-@)(?!.*#)([A-Z0-9._%+-]+)@([A-Z0-9.-]+\.[A-Z]{2,})$/i;
    let countrycode = getCountryCallingCode(country);

    const [enterval, setEnterVal] = useState('');
    const [emailtype, setEmailType] = useState(true);
    const [phonetype, setPhoneType] = useState(false);

    const handleChange = (label, value) => {
        switch (label) {
            case "email":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Email is required" : !exptest.test(value) ? "Invalid email" : null }));
                setVerifyData(value);
                break;
            case "phone_number":
                let checkval = `+${countrycode}` + value;
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: !isValidPhoneNumber(checkval) ? "Invalid Phone number" : null }));
                setVerifyData(value);
                break;

            default:
                setFormdata((prev) => ({ ...prev, [label]: value }));
                break;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let checkval = `+${countrycode}` + formdata.phone_number;

        if (emailtype) {
            setErrors((prev) => ({ ...prev, email: formdata.email === "" ? "Email is required" : null }));
        }

        if (phonetype) {
            setErrors((prev) => ({ ...prev, phone_number: formdata.phone_number === "" ? "Phone number is required" : !isValidPhoneNumber(checkval) ? "Invalid Phone number" : null }));
        }

        if (emailtype && (formdata.email === "" || !exptest.test(formdata.email))) return;

        if (phonetype && (formdata.phone_number === "" || !isValidPhoneNumber(checkval))) return;

        const userdata = {};
        if (emailtype) {
            userdata.email = formdata.email;
            delete userdata.phoneNo;
            delete userdata.countryCode;
        }
        if (phonetype) {
            delete userdata.email;
            userdata.phoneNo = formdata.phone_number;
            userdata.countryCode = `+${countrycode}`;
        }

        if (location.pathname === "/admin-verify") {
            userdata.type = "admin";
        } else {
            userdata.type = "user";
        }

        let otpsend = await user_send_otp(userdata, dispatch, toast, vendor_id);
        if (otpsend) {
            localStorage.setItem("verifyData", JSON.stringify(verifyData));

            if (location.pathname === "/admin-verify") {
                navigate("/admin-otp-verify");
            } else {
                navigate("/otp-verify");
            }
        }
    };

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
    }, []);

    return (
        <div className='d-grid justify-content-center mt-5 mb-5'>
            <Link className="d-inline-block" to="/login">Back</Link>
            <div className='d-flex justify-content-center gap-5'>
                <div>
                    <input
                        type='radio'
                        checked={emailtype}
                        onClick={() => { setEmailType(true); setPhoneType(false); }}
                    />
                    <span>Email</span>
                </div>
                <div>
                    <input
                        type='radio'
                        checked={phonetype}
                        onClick={() => { setPhoneType(true); setEmailType(false); }}
                    />
                    <span>Phone Number</span>
                </div>
            </div>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <div className='d-flex flex-column gap-3'>
                    <div className='col-md-5'>
                        <label className='form-label'>Enter {emailtype ? "Email" : "Phone No. "} : </label>
                        {
                            emailtype
                                ?
                                <div>
                                    <input
                                        type='email'
                                        className='form-group'
                                        placeholder='enter........'
                                        value={formdata.email}
                                        onChange={(e) => handleChange("email", e.target.value)}
                                    />
                                    {errors.email && <p className='text-danger'>{errors.email}</p>}
                                </div>
                                :
                                <div>
                                    <PhoneNumber
                                        country={country}
                                        setCountry={(val) => setCountry(val)}
                                        phoneNumber={formdata.phone_number}
                                        handleChange={(val) => handleChange('phone_number', val)}
                                    />
                                    {errors.phone_number && <p className='text-danger'>{errors.phone_number}</p>}
                                </div>
                        }
                    </div>
                    <div className='d-flex gap-3 col-md-6'>
                        <button type='submit' className='ylw-theme-button'>Send OTP</button>
                    </div>
                </div>
            </Form>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        vendor_id: state.auth.vendor_id
    }
}
export default connect(mapStateToProps)(EnterEmailorPhone);