import React from 'react'
import { Form } from 'react-bootstrap'
import PhoneNumber from './PhoneNumber'

const EditProfile = ({ formdata, errors, handleChange, handleSubmit, maxDate, country, setCountry }) => {

    return (
        <div>
            <Form onSubmit={(e) => handleSubmit(e)} noValidate>
                <div className='d-flex flex-wrap gap-1'>
                    <div className='col-md-5'>
                        <label className='form-label'>Username: </label>
                        <input
                            type='text'
                            className='form-control'
                            placeholder='username'
                            value={formdata.username}
                            onChange={(e) => handleChange('username', e.target.value)}
                        />
                        {errors.username && <p className='text-danger'>{errors.username}</p>}
                    </div>
                    <div className='col-md-5'>
                        <label className='form-label'>Email: </label>
                        <input
                            type='email'
                            className='form-control'
                            placeholder='email'
                            value={formdata.email}
                            onChange={(e) => handleChange('email', e.target.value)}
                            onKeyDown={(e) => e.key === " " && e.preventDefault()}
                        />
                        {errors.email && <p className='text-danger'>{errors.email}</p>}
                    </div>
                    <div className='col-md-5'>
                        <label className='form-label'>Name: </label>
                        <input
                            type='email'
                            className='form-control'
                            placeholder='email'
                            value={formdata.name}
                            onChange={(e) => handleChange('name', e.target.value)}
                        />
                        {errors.name && <p className='text-danger'>{errors.name}</p>}
                    </div>
                    <div className="col-md-5">
                        <label className="form-label">DOB</label>
                        <input
                            type="date"
                            name="dob"
                            placeholder=""
                            className="form-control"
                            value={formdata?.dob}
                            max={maxDate}
                            onChange={(e) => handleChange('dob', e.target.value)}
                        />
                        {errors.dob && (
                            <p className="d-flex flex-start text-danger error-msg mb-1 mb-md-0">
                                {errors.dob}
                            </p>
                        )}
                    </div>
                    {/* <div className='col-md-6'>
                        <label className='form-label'>Password: </label>
                        <input
                            type={isShow ? 'text' : 'password'}
                            className='form-control'
                            placeholder='******'
                            onChange={(e) => handleChange('password', e.target.value)}
                            onKeyDown={(e) => e.key === " " && e.preventDefault()}
                        />
                        {!isShow ? (
                            <i class="bi bi-eye-slash"
                                color="black"
                                size={20}
                                onClick={() => setIsShow(!isShow)}
                            />
                        ) : (
                            <i class="bi bi-eye"
                                color="black"
                                size={20}
                                onClick={() => setIsShow(!isShow)}
                            />
                        )}
                        {errors.password && <p className='text-danger'>{errors.password}</p>}
                    </div> */}
                    <div className='col-md-5'>
                        <label className='form-label'>Phone Number: </label>
                        <PhoneNumber
                            country={country}
                            setCountry={(val) => setCountry(val)}
                            phoneNumber={formdata.phone_number}
                            handleChange={(val) => handleChange('phone_number', val)}
                        />
                        {errors.phone_number && <p className='text-danger'>{errors.phone_number}</p>}
                    </div>
                    <div className='col-md-5'>
                        <label className='form-label'>Address: </label>
                        <textarea
                            minLength={4}
                            className='form-control'
                            placeholder='address details'
                            value={formdata.address}
                            onChange={(e) => handleChange('address', e.target.value)}
                        />
                        {errors.address && <p className='text-danger'>{errors.address}</p>}
                    </div>
                    <div className='col-md-6'>
                        <button type='submit' className='ylw-theme-button'>Save</button>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default EditProfile