import React from 'react'
import TravelImg from '../../assets/where-to-travel.png';
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t } = useTranslation();
    return (
        <div className='where-to-travel-wrappee'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 col-md-8 col-sm-12 where-to-travel-content-col'>
                        <div className='left-travel-box'>
                            <h2>{t('where_to_travel')}</h2>
                            <p className='p-md'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            <ul className='list-style-dots'>
                                <li className='p-md'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</li>
                                <li className='p-md'>Lorem Ipsum is simply dummy text of the printing.</li>
                                <li className='p-md'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                            </ul>
                            <div className='row mb-4 mobile_margin_add'>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='border-box-travel'>
                                        <p className='p-sm mb-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='border-box-travel'>
                                        <p className='p-sm mb-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                </div>
                            </div>
                            <a href="" class="btn btn-gradient-purple d-inline-block w-auto">{t('travel_with_us')}</a>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-4 col-sm-12 text-center where-to-travel-img-col'>
                        <div className='ryt-travel-box'>
                            <img src={TravelImg} alt='' className='img-fluid mx-auto d-block' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact