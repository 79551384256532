import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import PhoneNumber from '../shared/PhoneNumber';
import { getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input';
import { get_vender_id, signup_user } from '../reduxStore/User/userActions';
import { connect, useDispatch } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import moment from 'moment-timezone';
import { Link, useNavigate } from 'react-router-dom';

const Signup = ({ vendor_id }) => {
    const toast = useToast();
    const [formdata, setFormdata] = useState({
        username: '',
        email: '',
        dob: '',
        password: '',
        phone_number: '',
        address: ''
    });
    const [country, setCountry] = useState('US');

    const [errors, setErrors] = useState({
        username: '',
        email: '',
        dob: '',
        password: '',
        phone_number: '',
        address: ''
    });
    const exptest = /^(?!.*\.\.)(?!.*-@)(?!.*#)([A-Z0-9._%+-]+)@([A-Z0-9.-]+\.[A-Z]{2,})$/i;
    const [isShow, setIsShow] = useState(false);
    let countrycode = getCountryCallingCode(country);
    let checkval = `+${countrycode}` + formdata.phone_number;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const maxDate = moment.tz(userTimeZone).format('YYYY-MM-DD');

    const handleChange = (label, value) => {

        switch (label) {
            case "username":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Username is required" : null }));
                break;
            case "email":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Email is required" : !exptest.test(value) ? "Invalid email" : null }));
                break;
            case "dob":
                setErrors({ ...errors, dob: value === "" ? "DOB is required" : null });
                setFormdata({ ...formdata, [label]: value });
                break;
            case "password":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Password is required" : value.length <= 5 ? "Password should contain more than 5 characters" : null }));
                break;
            case "phone_number":
                let checkval = `+${countrycode}` + value;
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: !isValidPhoneNumber(checkval) ? "Invalid Phone number" : null }));
                break;
            case "address":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Address is required" : null }));
                break;

            default:
                setFormdata((prev) => ({ ...prev, [label]: value }));
                break;
        }
    };

    const handleValid = () => {
        let err = false;

        const Errors = {
            username: formdata.username === "" ? "Username is required" : null,
            address: formdata.address === "" ? "Address is required" : null,
            email: formdata.email === "" ? "Email is required" : !exptest.test(formdata.email) ? "Invalid email" : null,
            dob: formdata?.dob === "" ? "DOB is required" : null,
            password: formdata.password === "" ? "Password is required" : formdata.password.length <= 5 ? "Password should contain more than 5 characters" : null,
            phone_number: formdata.phone_number === "" ? "Phone number is required" : !isValidPhoneNumber(checkval) ? "Invalid Phone number" : null,
        };

        let newErrors = Object.entries(Errors);
        newErrors.forEach(([key, value]) => {
            if (value) {
                err = true;
                setErrors((prev) => ({ ...prev, [key]: value }));
            }
        });

        return err;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (handleValid()) return;

        const userdata = {
            ...formdata,
            country_code: `+${countrycode}`,
            country_code_name: country,
            name: formdata.username,
            role: "user"
        }
        let createuser = await signup_user(userdata, dispatch, toast, vendor_id);

        if (createuser) navigate("/login");
    };

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
    }, []);

    return (
        <div className=''>
            <div className='container'>
                <div className='row justify-content-end'>
                    <div className='col-xl-5 col-lg-6 col-md-8'>
                        <div className='login-form-wrap'>
                            <h1 className='text-center text-white mb-0'>Create New Account</h1>

                            <div class="d-flex justify-content-center align-items-center gap-5 my-4 social-login-icons-wrap">
                                <div className="d-inline-block text-white h4 mb-0 cursor-pointer">
                                    <i class="bi bi-google"></i>
                                </div>
                                <div className="d-inline-block text-white h4 mb-0 cursor-pointer">
                                    <i class="fab fa-facebook-f"></i>
                                </div>
                                <div className="d-inline-block text-white h3 mb-0 cursor-pointer">
                                    <i class="bi bi-apple"></i>
                                </div>
                            </div>

                            <p className='text-center text-white h5 mb-3 or-text'>or</p>

                            <Form onSubmit={(e) => handleSubmit(e)}>
                                <div className=''>
                                    <div className='form-group mb-3'>
                                        <label className='form-label text-white'>Name</label>
                                        <input
                                            type='text'
                                            className='form-group form-control'
                                            placeholder='Enter your name'
                                            value={formdata.username}
                                            onChange={(e) => handleChange('username', e.target.value)}
                                        />
                                        {errors.username && <p className='text-danger'>{errors.username}</p>}
                                    </div>
                                    <div className='form-group mb-3'>
                                        <label className='form-label text-white'>Email</label>
                                        <input
                                            type='email'
                                            className='form-group form-control'
                                            placeholder='Enter your email'
                                            value={formdata.email}
                                            onChange={(e) => handleChange('email', e.target.value)}
                                            onKeyDown={(e) => e.key === " " && e.preventDefault()}
                                        />
                                        {errors.email && <p className='text-danger'>{errors.email}</p>}
                                    </div>
                                    {/* <div className="form-group mb-3">
                                        <label className="form-label text-white">DOB</label>
                                        <input
                                            type="date"
                                            name="dob"
                                            placeholder=""
                                            className="form-control"
                                            defaultValue={formdata?.dob}
                                            max={maxDate}
                                            onChange={(e) => handleChange('dob', e.target.value)}
                                        />
                                        {errors.dob && (
                                            <p className="d-flex flex-start text-danger error-msg mb-1 mb-md-0">
                                                {errors.dob}
                                            </p>
                                        )}
                                    </div> */}
                                    <div className='form-group mb-3'>
                                        <label className='form-label text-white'>Phone Number</label>
                                        <PhoneNumber
                                            country={country}
                                            setCountry={(val) => setCountry(val)}
                                            phoneNumber={formdata.phone_number}
                                            handleChange={(val) => handleChange('phone_number', val)}
                                        />
                                        {errors.phone_number && <p className='text-danger'>{errors.phone_number}</p>}
                                    </div>
                                    <div className='form-group mb-3'>
                                        <label className='form-label text-white'>Set Password</label>
                                        <div className='position-relative pwd-field'>
                                            <input
                                                type={isShow ? 'text' : 'password'}
                                                className='form-group form-control'
                                                placeholder='******'
                                                onChange={(e) => handleChange('password', e.target.value)}
                                                onKeyDown={(e) => e.key === " " && e.preventDefault()}
                                            />
                                            {!isShow ? (
                                                <span className='pwd-eye-wrap'><i class="bi bi-eye-slash"
                                                    color="black"
                                                    size={20}
                                                    onClick={() => setIsShow(!isShow)}
                                                /></span>
                                            ) : (
                                                <span className='pwd-eye-wrap'><i class="bi bi-eye"
                                                    color="black"
                                                    size={20}
                                                    onClick={() => setIsShow(!isShow)}
                                                /></span>
                                            )}
                                        </div>
                                        {errors.password && <p className='text-danger'>{errors.password}</p>}
                                    </div>
                                    <div className='form-group mb-3'>
                                        <label className='form-label text-white'>Confirm Password </label>
                                        <div className='position-relative pwd-field'>
                                            <input
                                                type={isShow ? 'text' : 'password'}
                                                className='form-group form-control'
                                                placeholder='******'
                                                onChange={(e) => handleChange('password', e.target.value)}
                                                onKeyDown={(e) => e.key === " " && e.preventDefault()}
                                            />
                                            {!isShow ? (
                                                <span className='pwd-eye-wrap'><i class="bi bi-eye-slash"
                                                    color="black"
                                                    size={20}
                                                    onClick={() => setIsShow(!isShow)}
                                                /></span>
                                            ) : (
                                                <span className='pwd-eye-wrap'><i class="bi bi-eye"
                                                    color="black"
                                                    size={20}
                                                    onClick={() => setIsShow(!isShow)}
                                                /></span>
                                            )}
                                        </div>
                                        {errors.password && <p className='text-danger'>{errors.password}</p>}
                                    </div>
                                    {/* <div className='form-group mb-3'>
                                        <label className='form-label text-white'>Address: </label>
                                        <textarea
                                            minLength={4}
                                            className='form-group form-control'
                                            placeholder='address details'
                                            value={formdata.address}
                                            onChange={(e) => handleChange('address', e.target.value)}
                                        />
                                        {errors.address && <p className='text-danger'>{errors.address}</p>}
                                    </div> */}
                                </div>
                            </Form>

                            <div class="d-flex justify-content-between align-items-center gap-2 my-3 remember-forgot-text">
                                <div class="form-check">
                                    <input
                                        className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label className="form-check-label text-white" for="flexCheckDefault">
                                        I agree <Link to="#" className='text-decoration-underline'>Term & Condition.</Link>
                                    </label>
                                </div>
                            </div>
                            <div className=''>
                                <button type='submit' className='btn-gradient-purple'>Create Account</button>
                            </div>
                            {/* <div class="d-flex gap-2">
                    <span>Already, have an account?</span>
                    <Link to="/login">Login</Link>
                </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        vendor_id: state.auth.vendor_id
    }
}
export default connect(mapStateToProps)(Signup);