import React from 'react'
import HotelOne from '../../assets/hotel-left.png';
import HotelTwo from '../../assets/hotel-two.png';
import HotelThree from '../../assets/hotel-three.png';
import HotelFour from '../../assets/hotel-four.png';
import HotelFive from '../../assets/hotel-five.png';
import HotelSix from '../../assets/hotel-six.png';
import HotelsSeven from '../../assets/hotel-seven.png';
import HotelEight from '../../assets/hotel-eight.png';
import MapImg from '../../assets/map.png';
import GreenCheck from '../../assets/green-check.png';
import RoomOne from '../../assets/room1.png';
import WiFi from '../../assets/wifi.png';
import RecepTion from '../../assets/reception.png';
import SpaImg from '../../assets/spa.png';
import ClubImg from '../../assets/club.png';
import AirImg from '../../assets/air-condition.png';
import SmokeImg from '../../assets/smoke.png';
import GymImg from '../../assets/gym.png';
import FoodImg from '../../assets/food.png';
import AtmImg from '../../assets/atmb.png';
import ParkImg from '../../assets/parking.png';
import OutdoorImg from '../../assets/pool.png';
import GardImg from '../../assets/garden.png';
import BreakfastImgg from '../../assets/breakfast.png';
import CafeImg from '../../assets/cafe.png';
import LaundryImg from '../../assets/laundry.png';
import LiftsImg from '../../assets/lifts.png';
import WifibtnImg from '../../assets/wifibtm.png';
import AtmbtmImg from '../../assets/atm_btm.png';
import ReceptionImg from '../../assets/24recep.png';
import AirconditionImg from '../../assets/air-condition-btm.png';
import BanquetImg from '../../assets/banquet.png';
import ParkingbtmImg from '../../assets/parkingbt.png';
import SelectOne from '../../assets/Rectangle-54.png'
import RoomsCard from '../../shared/RoomsCard';
import Slider from 'react-slick';
import Products from '../../shared/Products';
import { Box } from '@chakra-ui/react';


const HotelDetail = () => {
    const roomsData = [1, 2, 3, 4, 5, 6];

    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },

            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const productssettings = {
        dots: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const popupssettings = {
        dots: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };



    return (
        <div className=''>
            <div className='hotel-details-banner'>
                <div className='container'>
                    <div className='top-home-detail-wrapper tab-description'>
                        <h2>Movenpick Hotel And Residences Riyadh</h2>
                        <div class="reviews-box d-flex align-items-end gap-2 mb-1">
                            <p className='d-inline-block font-size-15 mb-0 font-weight-500'>4.3</p>
                            <ul class="d-inline-block star-rating p-0 mb-0">
                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                <li class="d-inline-block"><i class="fas fa-star"></i></li><li class="d-inline-block"><i class="fas fa-star"></i></li>
                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                            </ul>
                            <p className='d-inline-block font-size-15 mb-0 font-weight-500'>(144 reviews)</p>
                        </div>
                        <p className='font-size-15 font-weight-500 mb-0 d-flex align-items-center gap-2'><img src={MapImg} className='d-inline-block' alt='' />Diplomatic Enclave Chanakyapuri</p>
                    </div>
                </div>

                <div className='container hotel-details-banner-inner-bg' id="photos-tab">
                    <div className='row w-100 mx-auto'>
                        <div className='col-lg-7 col-md-7 col-sm-12 p-0'>
                            <div className='left-hotel-banner spacing-btm'>
                                <img src={HotelOne} alt='' className='img-fluid w-100' />
                            </div>
                            <div className='row bottom_banner_img w-100 mx-auto'>
                                <div className='col-lg-4 col-md-4 col-sm-12 p-0'>
                                    <div className=''>
                                        <img src={HotelSix} className='img-fluid w-100' alt='' />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12 p-0'>
                                    <div className='spacing-ryt'>
                                        <img src={HotelsSeven} className='img-fluid w-100' alt='' />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12 p-0'>
                                    <div className='spacing-ryt-lg'>
                                        <img src={HotelEight} className='img-fluid w-100' alt='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-5 col-sm-12 ryt-col-spacing-tab p-0'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
                                    <div className='spacing-equal'>
                                        <img src={HotelTwo} className='img-fluid w-100' alt='' />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
                                    <div className='spacing-equal'>
                                        <img src={HotelThree} className='img-fluid w-100' alt='' />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
                                    <div className='spacing-equal'>
                                        <img src={HotelFour} className='img-fluid w-100' alt='' />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
                                    <div className='spacing-equal blur-img'>
                                        <div className='slider-image-popup'>
                                            <img src={HotelFive} className='img-fluid w-100' alt='' />
                                            <div className='modal-click-btn'>
                                                <button type="button" class="bbtn btn-gradient-purple text-white " data-bs-toggle="modal" data-bs-target="#slidermodal">
                                                    View all
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mobile-hotel-details-banner'>
                    <div className='container'>
                        <Slider {...settings}>
                            {roomsData?.map((item, index) => (
                                <div className='col-12' key={index}>
                                    <img src={HotelOne} className='img-fluid w-100' />
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>

                <div className='hotel-details-wrapper'>
                    <div className='container'>
                        <ul className='p-0 border-bottom'>
                            <li className='d-inline-block'>
                                <a class="nav-link page-id-tabs active" href='#photos-tab'>Photos</a>
                            </li>
                            <li className='d-inline-block'>
                                <a class="nav-link page-id-tabs" href='#amenities-tab'>Amenities</a>
                            </li>
                            <li className='d-inline-block'>
                                <a class="nav-link page-id-tabs" href='#rooms-tab'>Rooms</a>
                            </li>
                            <li className='d-inline-block'>
                                <a class="nav-link page-id-tabs" href='#policies-tab'>Policies </a>
                            </li>
                            <li className='d-inline-block'>
                                <a class="nav-link page-id-tabs" href='#reviews-tab'>Reviews</a>
                            </li>
                            <li className='d-inline-block'>
                                <a class="nav-link page-id-tabs" href='#similarpr-tab'>Similar Product</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='hotel_bottm_detail'>
                    <div className='container'>
                        <p className='font-size-15 font-weight-500'>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        </p>
                        <p className='font-size-15 font-weight-500 mb-0'>
                            It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                    </div>
                </div>

                <div className='amnenities-wrapper' id="amenities-tab">
                    <div className='container'>
                        <h2>Amenities</h2>
                        <ul className='p-0 list-box-amenities'>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={WiFi} className='d-inline-block' alt='' />
                                    Free Wi-fi
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={RecepTion} className='d-inline-block' alt='' />
                                    24H. Reception
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={SpaImg} className='d-inline-block' alt='' />
                                    Spa
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={ClubImg} className='d-inline-block' alt='' />Club
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={AirImg} className='d-inline-block' alt='' />Air Condition
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={SmokeImg} className='d-inline-block' alt='' />Smoking Area
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={GymImg} className='d-inline-block' alt='' />Gym
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={FoodImg} className='d-inline-block' alt='' />Food & Drink
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={AtmImg} className='d-inline-block' alt='' />ATM
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={ParkImg} className='d-inline-block' alt='' />Parking</p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={OutdoorImg} className='d-inline-block' alt='' />
                                    Outdoor Pool
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'>
                                    <img src={GardImg} className='d-inline-block' alt='' />Garden
                                </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={BreakfastImgg} className='d-inline-block' alt='' />Breakfast</p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={CafeImg} className='d-inline-block' alt='' />Café</p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={LaundryImg} className='d-inline-block' alt='' />Laundry </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={LiftsImg} className='d-inline-block' alt='' />Lifts</p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='details-room-srch booking-tab-form'>
                    <div className='container'>
                        <div className='details_bg_lyt'>
                            <form className='w-100'>
                                <div className='d-grid grid-wrapper-form'>
                                    <div className='destination-box first-box'>
                                        <input type='date' className='form-control' />
                                    </div>
                                    <div className='destination-box first-box'>
                                        <input type='date' className='form-control' />
                                    </div>
                                    <div className='destination-box first-box'>
                                            <img src="./images/people-outline.png" className='img-fluid' alt='' />
                                            <input type='text' className='form-control' placeholder='1 Room, 2 Adults, 0 Children, 0 Pets' />
                                    </div>
                                    <div className='destination-box first-box button-last-box'>
                                        <button type="submit" className='btn btn-gradient-purple text-white d-flex align-items-center justify-content-center gap-2'><i class="fas fa-search me-2"></i> Modify Search</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div id='rooms-tab' className='related_rooms_wrapper top-related_rooms_wrapper'>
                    <div className='container'>
                        <h2 className='mb-2'>Rooms</h2>
                        <div className='row'>
                            <Slider {...settings}>
                                {roomsData?.map((item, index) => (
                                    <div className='col-lg-4 col-md-4 col-sm-12' key={index}>
                                        <RoomsCard item={item} />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>

                <div className='Selected_rooms'>
                    <div className='container'>
                        <div className='d-flex align-items-center justify-content-between mb-3'>
                            <h2 className='mb-0'>Selected Rooms(3)</h2>
                            <p className='mb-0'>Remove all</p>
                        </div>
                        <form>
                            <div className='select-room_grid_row row'>
                                <div className='col-lg-3 col-md-6 col-sm-12 select-col-spacing'>
                                    <div className='select-room_inner d-flex align-items-center justify-content-between gap-2'>
                                        <div className=''>
                                            <img src={SelectOne} className='d-inline-block' />
                                        </div>
                                        <div className=''>
                                            <p className='mb-0 dark-txt'>Regular Room - Queen Bed</p>
                                            <div className='d-grid align-items-center price-quantity-btn'>
                                                <p className='mb-0 d-inline-block'><span className='yellow-txt-link me-2 font-weight-600'>$35</span> x</p>
                                                <div class="quantity-increase input-group d-flex align-items-start">
                                                    <input type="button" value="-" class="button-minus rounded-circle-quantity" data-field="quantity" />
                                                    <input type="number" step="1" max="" value="1" name="quantity" class="quantity-field d-inline-block mx-0 text-center font-weight-500" />
                                                    <input type="button" value="+" class="button-plus rounded-circle-quantity" data-field="quantity" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <i class="fas fa-times"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 select-col-spacing'>
                                    <div className='select-room_inner d-flex align-items-center justify-content-between gap-2'>
                                        <div className=''>
                                            <img src={SelectOne} className='d-inline-block' />
                                        </div>
                                        <div className=''>
                                            <p className='mb-0 dark-txt'>Regular Room - Queen Bed</p>
                                            <div className='d-grid align-items-center price-quantity-btn'>
                                                <p className='mb-0 d-inline-block'><span className='yellow-txt-link me-2 font-weight-600'>$35</span> x</p>
                                                <div class="quantity-increase input-group d-flex align-items-start">
                                                    <input type="button" value="-" class="button-minus rounded-circle-quantity" data-field="quantity" />
                                                    <input type="number" step="1" max="" value="1" name="quantity" class="quantity-field d-inline-block mx-0 text-center font-weight-500" />
                                                    <input type="button" value="+" class="button-plus rounded-circle-quantity" data-field="quantity" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <i class="fas fa-times"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 select-col-spacing'>
                                    <div className='select-room_inner d-flex align-items-center justify-content-between gap-2'>
                                        <div className=''>
                                            <img src={SelectOne} alt='' className='d-inline-block' />
                                        </div>
                                        <div className=''>
                                            <p className='mb-0 dark-txt'>Regular Room - Queen Bed</p>
                                            <div className='d-grid align-items-center price-quantity-btn'>
                                                <p className='mb-0 d-inline-block'><span className='yellow-txt-link me-2 font-weight-600'>$35</span> x</p>
                                                <div class="quantity-increase input-group d-flex align-items-start">
                                                    <input type="button" value="-" class="button-minus rounded-circle-quantity" data-field="quantity" />
                                                    <input type="number" step="1" max="" value="1" name="quantity" class="quantity-field d-inline-block mx-0 text-center font-weight-500" />
                                                    <input type="button" value="+" class="button-plus rounded-circle-quantity" data-field="quantity" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <i class="fas fa-times"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-6 col-sm-12 select-col-spacing'>
                                    <button type="submit" className='btn btn-gradient-purple'>Book Now</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className='policies-wrapper' id='policies-tab'>
                    <div className='container'>
                        <h2 className='mb-3'>Policies</h2>
                        <div className='row'>
                            <div className='col-lg-4 col-md-6 col-sm-12 policy-mb-space'>
                                <div className='policies_box_inner'>
                                    <h3>Property policies</h3>
                                    <p className='font-size-15 font-weight-500 mb-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <ul className='list-style-dots'>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 policy-mb-space'>
                                <div className='policies_box_inner'>
                                    <h3>Property description</h3>
                                    <p className='font-size-15 font-weight-500 mb-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <ul className='list-style-dots'>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 policy-mb-space'>
                                <div className='policies_box_inner'>
                                    <h3>Important information</h3>
                                    <p className='font-size-15 font-weight-500 mb-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <ul className='list-style-dots'>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 policy-mb-space'>
                                <div className='policies_box_inner'>
                                    <h3>Fees and extras</h3>
                                    <p className='font-size-15 font-weight-500 mb-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <ul className='list-style-dots'>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 policy-mb-space'>
                                <div className='policies_box_inner'>
                                    <h3>Cancellation Charges </h3>
                                    <p className='font-size-15 font-weight-500 mb-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <ul className='list-style-dots'>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                        <li className='font-size-15 font-weight-500'>Lorem Ipsum is simply dummy text</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='related_rooms_wrapper similar_rooms_wrapper pt-0' id='similarpr-tab'>
                    <div className='container'>
                        <h2 className='mb-4'>Similar Products</h2>
                        <div className='row'>
                            <Slider {...productssettings}>
                                {roomsData?.map((item, index) => (
                                    <div className='col-lg-4 col-md-4 col-sm-12' key={index}>
                                        <Products item={item} />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>



                <div class="hotel-details-modal modal fade" id="slidermodal" tabindex="-1" aria-labelledby="slidermodalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header border-0 position-absolute cross-icon">
                                {/* <h5 class="modal-title" id="slidermodalLabel">Modal title</h5> */}
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body border-0 desktop-slider-view p-0">
                                <Slider {...popupssettings}>
                                    {roomsData?.map((item, index) => (
                                        <div className='col-12' key={index}>
                                            <img src={HotelOne} alt='' className='img-fluid w-100' />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default HotelDetail