import React from 'react'
import { Link } from 'react-router-dom'

const SendEmail = () => {
    return (
        <div className='d-flex flex-column justify-content-center align-items-center'>
            <h1>Check Your Email</h1>
            <div className='d-flex flex-column'>
                <span>We have sent you a password reset link to your email</span>
                <span>with futhur instructions. Please check your mail to rest</span>
                <span>your password.</span>
            </div>
            <Link to="/login" className='btn-gradient-purple w-50'>Back to Login</Link>
        </div>
    )
}

export default SendEmail