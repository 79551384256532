import { GET_ALL_ADMINS_LISTS, GET_ALL_PLANS, GET_ALL_USERS_LISTS, GET_CURRENCY, GET_EDIT_DATA, GET_LANGUAGE, GET_USER_DETAILS, GET_USERS_TOTAL, GET_VENDOR_ID, IS_EDIT, LOGOUT, USER_UPDATE } from "./userTypes";

const initialState = {
    user: JSON.parse(localStorage.getItem("userData")) || null,
    isEdit: false,
    userDetails: null,
    allUsers: [],
    total_users: 0,
    allAdmins: [],
    editData: null,
    allPlans: [],
    vendor_id: null,
    currency: 'USD',
    language: 'en'
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_UPDATE:
            return {
                ...state,
                user: action.payload,
            };
        case LOGOUT:
            return {
                ...state,
                user: null,
            };
        case IS_EDIT:
            return {
                ...state,
                isEdit: action.payload,
            };
        case GET_USER_DETAILS:
            return {
                ...state,
                userDetails: action.payload,
            };
        case GET_ALL_USERS_LISTS:
            return {
                ...state,
                allUsers: action.payload,
            };
        case GET_USERS_TOTAL:
            return {
                ...state,
                total_users: action.payload,
            };
        case GET_ALL_ADMINS_LISTS:
            return {
                ...state,
                allAdmins: action.payload,
            };
        case GET_EDIT_DATA:
            return {
                ...state,
                editData: action.payload,
            };
        case GET_ALL_PLANS:
            return {
                ...state,
                allPlans: action.payload,
            };
        case GET_VENDOR_ID:
            return {
                ...state,
                vendor_id: action.payload,
            };
        case GET_CURRENCY:
            return {
                ...state,
                currency: action.payload,
            };
        case GET_LANGUAGE:
            return {
                ...state,
                language: action.payload,
            };
        default:
            return state;
    }
};

export default userReducer;