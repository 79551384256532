import axios from "axios";
import { GET_ALL_LANGUAGES_LIST, GET_ALL_MEDIUM_AGENCIES, GET_ALL_SMALL_AGENCIES, GET_ALL_STAFF_MEMBERS, GET_ALL_USERS, GET_LANGUAGE_DATA, GET_TOTAL_MEDIUM, GET_TOTAL_SMALL, GET_TOTAL_STAFF_MEMBERS, GET_USERS_TOTAL } from "./vendorTypes";

const { REACT_APP_OTA_URL } = process.env;

export const admin_create_agency = async (vendorId, token, agencydata, toast) => {
    try {
        const url = `${REACT_APP_OTA_URL}agency/register/${vendorId}`;
        const HEADERS = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json",
            }
        };
        const res = await axios.post(url, agencydata, HEADERS);
        if (res.data && res.data.status) {
            toast({ title: res.data.message, status: "success", duration: 2000, position: "top-right", isClosable: true, });
            return true;
        } else {
            toast({ title: res.data.message, status: "error", duration: 2000, position: "top-right", isClosable: true, });
            return false;
        }
    } catch (error) {
        console.log("Err in Agency Create", error);
    }
};

export const staff_create_agency = async (vendorId, token, agencydata, toast) => {
    try {
        const url = `${REACT_APP_OTA_URL}agency/create-staff/${vendorId}`;
        const HEADERS = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json",
            }
        };
        const res = await axios.post(url, agencydata, HEADERS);
        if (res.data && res.data.status) {
            toast({ title: res.data.message, status: "success", duration: 2000, position: "top-right", isClosable: true, });
            return true;
        } else {
            toast({ title: res.data.message, status: "error", duration: 2000, position: "top-right", isClosable: true, });
            return false;
        }
    } catch (error) {
        console.log("Err in Staff Create", error);
    }
};

export const get_all_staff_members = async (token, dispatch, vendor_id) => {
    try {
        const url = `${REACT_APP_OTA_URL}agency/staff-list/${vendor_id}`;
        const HEADERS = {
            headers: {
                "x-access-token": token,
            }
        };
        const res = await axios.get(url, HEADERS);
        if (res.data && res.data.status) {
            dispatch({ type: GET_ALL_STAFF_MEMBERS, payload: res.data.data });
            // dispatch({ type: GET_TOTAL_STAFF_MEMBERS, payload: res.data.length });
        }
    } catch (error) {
        console.log("Err in Small Lists", error);
    }
};

export const get_all_small_agencies = async (token, dispatch, vendor_id) => {
    try {
        const url = `${REACT_APP_OTA_URL}agency/small-list/${vendor_id}`;
        const HEADERS = {
            headers: {
                "x-access-token": token,
            }
        };
        const res = await axios.get(url, HEADERS);
        if (res.data && res.data.status) {
            dispatch({ type: GET_ALL_SMALL_AGENCIES, payload: res.data.data });
            dispatch({ type: GET_TOTAL_SMALL, payload: res.data.length });
        }
    } catch (error) {
        console.log("Err in Small Lists", error);
    }
};

export const get_all_medium_agencies = async (token, dispatch, vendor_id) => {
    try {
        const url = `${REACT_APP_OTA_URL}agency/medium-list/${vendor_id}`;
        const HEADERS = {
            headers: {
                "x-access-token": token,
            }
        };
        const res = await axios.get(url, HEADERS);
        if (res.data && res.data.status) {
            dispatch({ type: GET_ALL_MEDIUM_AGENCIES, payload: res.data.data });
            dispatch({ type: GET_TOTAL_MEDIUM, payload: res.data.length });
        }
    } catch (error) {
        console.log("Err in Medium Lists", error);
    }
};

export const create_lang_title_by_admin = async (token, data, toast) => {
    try {
        const url = `${REACT_APP_OTA_URL}page/add-key`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
            }
        };
        const res = await axios.post(url, JSON.stringify(data), HEADERS);
        if (res.data && res.data.status) {
            toast({ title: res.data.message, status: "success", duration: 2000, position: "top-right", isClosable: true, });
            return true;
        }
    } catch (error) {
        console.log("Err in Lang Creation ---->", error);
    }
};

export const get_language_data = async (langtype, token, dispatch, toast, vendor_id) => {
    try {
        const url = `${REACT_APP_OTA_URL}page/${vendor_id}?language=${langtype}`;
        const HEADERS = {
            headers: {
                "x-access-token": token,
            }
        };
        const res = await axios.get(url, HEADERS);
        if (res.data && res.data.status) {
            dispatch({ type: GET_LANGUAGE_DATA, payload: res.data.data });
            return res.data.data;
        }
    } catch (error) {
        toast({ title: error.response.data.message, status: "error", duration: 2000, position: "top-right", isClosable: true, });
        // console.log("Err in Lang Data ---->", error);
    }
};

export const get_all_languages_lists = async (token, dispatch, type) => {
    try {
        const url = type ? `${REACT_APP_OTA_URL}agency/language-list?type=${type}` : `${REACT_APP_OTA_URL}agency/language-list`;
        const HEADERS = {
            headers: {
                "x-access-token": token,
            }
        };
        const res = await axios.get(url, HEADERS);
        if (res.data && res.data.status) {
            dispatch({ type: GET_ALL_LANGUAGES_LIST, payload: res.data.data });
            return res.data.data;
        }
    } catch (error) {
        console.log("Error in Language List ---->", error);
    }
};

export const apply_language_active = async (token, data) => {
    try {
        const url = `${REACT_APP_OTA_URL}agency/language`;
        const HEADERS = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token,
            }
        };
        const res = await axios.put(url, JSON.stringify(data), HEADERS);
        if (res.data && res.data.status) {
            return true;
        }
    } catch (error) {
        console.log("Error in Language List ---->", error);
    }
};

