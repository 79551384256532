import React from 'react'
import HotelsImg from '../assets/hotel1.jpg';
import Slider from 'react-slick';

const OffersCard = () => {

    return (
        <div>
            <div className='offer-card-box'>
                <div className="card border-0 mb-3 border-0">
                    <div className="">
                         <img src={HotelsImg} className="img-fluid" alt="..." />
                    </div>
                    <div className="">
                        <div className="card-body">
                            <h5 className="mb-0">#SUPERSAVER OFFER: FLAT 10% OFF*</h5>
                            <p className="card-text p-sm mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                            <a href="url{('')}" className='p-md yellow-txt-link'>Book Now <i class="fas fa-arrow-right ms-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OffersCard