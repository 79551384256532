import React from 'react'
import { useNavigate } from 'react-router-dom';
import HotelOne from '../../assets/hotel-left.png';
import HotelTwo from '../../assets/hotel-two.png';
import HotelThree from '../../assets/hotel-three.png';
import HotelFour from '../../assets/hotel-four.png';
import HotelFive from '../../assets/hotel-five.png';
import HotelSix from '../../assets/hotel-six.png';
import HotelsSeven from '../../assets/hotel-seven.png';
import HotelEight from '../../assets/hotel-eight.png';
import MapImg from '../../assets/map.png';
import GreenCheck from '../../assets/green-check.png';
import RoomOne from '../../assets/room1.png';
import WiFi from '../../assets/wifi.png';
import RecepTion from '../../assets/reception.png';
import SpaImg from '../../assets/spa.png';
import ClubImg from '../../assets/club.png';
import AirImg from '../../assets/air-condition.png';
import SmokeImg from '../../assets/smoke.png';
import GymImg from '../../assets/gym.png';
import FoodImg from '../../assets/food.png';
import AtmImg from '../../assets/atmb.png';
import ParkImg from '../../assets/parking.png';
import OutdoorImg from '../../assets/pool.png';
import GardImg from '../../assets/garden.png';
import BreakfastImgg from '../../assets/breakfast.png';
import CafeImg from '../../assets/cafe.png';
import LaundryImg from '../../assets/laundry.png';
import LiftsImg from '../../assets/lifts.png';
import WifibtnImg from '../../assets/wifibtm.png';
import AtmbtmImg from '../../assets/atm_btm.png';
import ReceptionImg from '../../assets/24recep.png';
import AirconditionImg from '../../assets/air-condition-btm.png';
import BanquetImg from '../../assets/banquet.png';
import ParkingbtmImg from '../../assets/parkingbt.png';
import SelectOne from '../../assets/Rectangle-54.png'
import carImg from '../../assets/car-sport-outline .png'
import CheckinImg from '../../assets/restaurant-outline.png'
import DinnerImg from '../../assets/bed-outline.png'
import Slider from 'react-slick';
import Products from '../../shared/Products';
import ClockImg from '../../assets/clock.png';


const TourDetail = () => {
    const navigate = useNavigate();
    const roomsData = [1, 2, 3, 4, 5, 6];

    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },

            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const productssettings = {
        dots: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const popupssettings = {
        dots: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    return (
        <div>
            <div className='hotel-details-banner'>
                <div className='container'>
                    <div className='top-home-detail-wrapper tab-description'>
                        <h2>Movenpick Hotel And Residences Riyadh</h2>
                        <div class="reviews-box d-flex align-items-end gap-2">
                            <p className='d-inline-block p-lg mb-0'>4.3</p>
                            <ul class="d-inline-block star-rating p-0 mb-0">
                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                <li class="d-inline-block"><i class="fas fa-star"></i></li><li class="d-inline-block"><i class="fas fa-star"></i></li>
                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                            </ul>
                            <p className='d-inline-block p-lg mb-0'>(144 reviews)</p>
                        </div>
                    </div>
                </div>

                <div className='container hotel-details-banner-inner-bg' id="photos-tab">
                    <div className='row w-100 mx-auto'>
                        <div className='col-lg-7 col-md-7 col-sm-12 p-0'>
                            <div className='left-hotel-banner spacing-btm'>
                                <img src={HotelOne} alt='' className='img-fluid w-100' />
                            </div>
                            <div className='row bottom_banner_img w-100 mx-auto'>
                                <div className='col-lg-4 col-md-4 col-sm-12 p-0'>
                                    <div className=''>
                                        <img src={HotelSix} className='img-fluid w-100' alt='' />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12 p-0'>
                                    <div className='spacing-ryt'>
                                        <img src={HotelsSeven} className='img-fluid w-100' alt='' />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12 p-0'>
                                    <div className='spacing-ryt-lg'>
                                        <img src={HotelEight} className='img-fluid w-100' alt='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-5 col-sm-12 ryt-col-spacing-tab p-0'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
                                    <div className='spacing-equal'>
                                        <img src={HotelTwo} className='img-fluid w-100' alt='' />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
                                    <div className='spacing-equal'>
                                        <img src={HotelThree} className='img-fluid w-100' alt='' />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
                                    <div className='spacing-equal'>
                                        <img src={HotelFour} className='img-fluid w-100' alt='' />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
                                    <div className='spacing-equal blur-img'>
                                        <div className='slider-image-popup'>
                                            <img src={HotelFive} className='img-fluid w-100' alt='' />
                                            <div className='modal-click-btn'>
                                                <button type="button" class="bbtn btn-gradient-purple text-white " data-bs-toggle="modal" data-bs-target="#slidermodal">
                                                    View all
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mobile-hotel-details-banner'>
                    <div className='container'>
                        <Slider {...settings}>
                            {roomsData?.map((item, index) => (
                                <div className='col-12' key={index}>
                                    <img src={HotelOne} className='img-fluid w-100' />
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>

                <div className='hotel-details-wrapper'>
                    <div className='container'>
                        <ul className='p-0 border-bottom'>
                            <li className='d-inline-block'>
                                <a class="nav-link page-id-tabs active" href='#photos-tab'>Photos</a>
                            </li>
                            <li className='d-inline-block'>
                                <a class="nav-link page-id-tabs" href='#amenities-tab'>Amenities</a>
                            </li>
                            {/* <li className='d-inline-block'>
                                <a class="nav-link page-id-tabs" href='#rooms-tab'>Rooms</a>
                            </li> */}
                            <li className='d-inline-block'>
                                <a class="nav-link page-id-tabs" href='#policies-tab'>Policies </a>
                            </li>
                            <li className='d-inline-block'>
                                <a class="nav-link page-id-tabs" href='#reviews-tab'>Reviews</a>
                            </li>
                            <li className='d-inline-block'>
                                <a class="nav-link page-id-tabs" href='#similarpr-tab'>Similar Product</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='hotel_bottm_detail'>
                    <div className='container'>
                        <p className='p-lg'>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        </p>
                        <p className='p-lg mb-0'>
                            It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                    </div>
                </div>

                <div className='amnenities-wrapper' id="amenities-tab">
                    <div className='container'>
                        <h2>Amenities</h2>
                        <ul className='p-0 list-box-amenities'>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={WiFi} className='d-inline-block' alt='' />Free Wi-fi</p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={RecepTion} className='d-inline-block' alt='' />24H. Reception</p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={SpaImg} className='d-inline-block' alt='' />Spa</p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={ClubImg} className='d-inline-block' alt='' />Club</p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={AirImg} className='d-inline-block' alt='' />Air Condition</p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={SmokeImg} className='d-inline-block' alt='' />Smoking Area</p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={GymImg} className='d-inline-block' alt='' />Gym</p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={FoodImg} className='d-inline-block' alt='' />Food & Drink</p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={AtmImg} className='d-inline-block' alt='' />ATM</p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={ParkImg} className='d-inline-block' alt='' />Parking</p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={OutdoorImg} className='d-inline-block' alt='' />Outdoor Pool</p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={GardImg} className='d-inline-block' alt='' />Garden</p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={BreakfastImgg} className='d-inline-block' alt='' />Breakfast</p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={CafeImg} className='d-inline-block' alt='' />Café</p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={LaundryImg} className='d-inline-block' alt='' />Laundry </p>
                            </li>
                            <li className='p-md d-inline-block'>
                                <p className='d-flex align-items-center gap-2'><img src={LiftsImg} className='d-inline-block' alt='' />Lifts</p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='daily-details-wrapper'>
                    <div className='container'>
                        <h2 className='mb-4'>Daily Details</h2>
                        <div className='row'>
                            <div className='col-lg-7 col-md-12 col-sm-12'>
                                <div className='daily-update-box'>
                                    <h4><span className='text-purple'>Day 1</span> - Arrival in Kolkata</h4>
                                    <ul className='box-list-details-wrap'>
                                        <li className='text-grey-light p-large'><img src={carImg} className='d-inline-block me-2' />Pickup from Airport </li>

                                        <li className='text-grey-light p-large'><img src={CheckinImg} className='d-inline-block me-2' />Check-in to Hotel </li>

                                        <li className='text-grey-light p-large'><img src={DinnerImg} className='d-inline-block me-2' />Dinner </li>
                                    </ul>
                                </div>

                                <div className='daily-update-box'>
                                    <h4><span className='text-purple'>Day 2</span> - Visit Temples in Kolkata</h4>
                                    <ul className='box-list-details-wrap'>
                                        <li className='text-grey-light p-large'><img src={carImg} className='d-inline-block me-2' />Breakfast </li>

                                        <li className='text-grey-light p-large'><img src={CheckinImg} className='d-inline-block me-2' />Temple in Kolkata </li>

                                        <li className='text-grey-light p-large'><img src={DinnerImg} className='d-inline-block me-2' />2nd Temple in Kolkata </li>

                                        <li className='text-grey-light p-large'><img src={DinnerImg} className='d-inline-block me-2' />Dinner </li>
                                    </ul>
                                </div>

                                <div className='daily-update-box'>
                                    <h4><span className='text-purple'>Day 2</span> - Free</h4>
                                    <ul className='box-list-details-wrap'>
                                        <li className='text-grey-light p-large'><img src={carImg} className='d-inline-block me-2' />Breakfast  </li>

                                        <li className='text-grey-light p-large'><img src={CheckinImg} className='d-inline-block me-2' />Free </li>

                                        <li className='text-grey-light p-large'><img src={DinnerImg} className='d-inline-block me-2' />Candle light Dinner </li>
                                    </ul>
                                </div>

                                <div className='daily-update-box'>
                                    <h4><span className='text-purple'>Day 3</span> - Departure</h4>
                                    <ul className='box-list-details-wrap'>
                                        <li className='text-grey-light p-large'><img src={carImg} className='d-inline-block me-2' />Breakfast  </li>

                                        <li className='text-grey-light p-large'><img src={CheckinImg} className='d-inline-block me-2' />Drop to Airport  </li>
                                    </ul>
                                </div>
                            </div>

                            <div className='col-lg-5 col-md-12 col-sm-12'>
                                <div className='tour-details-timming'>
                                    <div className='timming-txt-wrap d-flex justify-content-between'>
                                        <div className=''>
                                            <h4 className='mb-1'>Timing</h4>
                                            <p className='p-md mb-0'><img src={ClockImg} className='d-inline-block me-1' />3 Days, 4 Nights</p>
                                        </div>
                                        <div className='orange-box-price'>
                                            <p className='mb-0 p-sm'><strike>59,999</strike></p>
                                            <p className='mb-0 p-large text-color-theme font-weight-600'>₹55,750 <small className='p-sm text-grey-light fst-normal'>/Adult</small></p>
                                        </div>
                                    </div>
                                    <div className='flight-features-txt'>
                                        <ul className='p-0 flight-features-list m-0'>
                                            <li className='d-inline-block text-grey-light p-md lh-1'>1 Flight</li>
                                            <li className='d-inline-block text-grey-light p-md lh-1'>1 Hotel</li>
                                            <li className='d-inline-block text-grey-light p-md lh-1'>1 Transfer</li>
                                        </ul>
                                    </div>

                                    <div className='border-divider'></div>

                                    <div className='ryt-purple-box'>
                                        <h4>Travellers</h4>
                                        <div class="quantity-increase input-group d-flex align-items-start">
                                            <input type="button" value="-" class="button-minus rounded-circle-quantity" data-field="quantity" />
                                            <input type="number" step="1" max="" value="1" name="quantity" class="quantity-field d-inline-block mx-0 text-center font-weight-500" />
                                            <input type="button" value="+" class="button-plus rounded-circle-quantity" data-field="quantity" />
                                        </div>
                                    </div>

                                    <div className='border-divider'></div>

                                    <div className='mb-3'>
                                        <h4>Total</h4>
                                        <h3 className='font-25-hd font-family-poppins'>₹1,11,500</h3>
                                    </div>

                                    <div className='text-center'>
                                        <p className='p-md mb-1'>*Adult - Should be above 18 years</p>
                                    </div>

                                    <a onClick={() => navigate('/tour-payment')} className='bbtn btn-gradient-purple text-white w-100 d-block text-center'>Book Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='policies-wrapper' id='policies-tab'>
                    <div className='container'>
                        <h2 className='mb-3'>Policies</h2>
                        <div className='row'>
                            <div className='col-lg-4 col-md-6 col-sm-12'>
                                <div className='policies_box_inner'>
                                    <h3>Property policies</h3>
                                    <p className='p-lg mb-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <ul className='list-style-dots'>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12'>
                                <div className='policies_box_inner'>
                                    <h3>Property description</h3>
                                    <p className='p-lg mb-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <ul className='list-style-dots'>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12'>
                                <div className='policies_box_inner'>
                                    <h3>Important information</h3>
                                    <p className='p-lg mb-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <ul className='list-style-dots'>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12'>
                                <div className='policies_box_inner'>
                                    <h3>Fees and extras</h3>
                                    <p className='p-lg mb-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <ul className='list-style-dots'>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12'>
                                <div className='policies_box_inner'>
                                    <h3>Cancellation Charges </h3>
                                    <p className='p-lg mb-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <ul className='list-style-dots'>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                        <li className='p-lg'>Lorem Ipsum is simply dummy text</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='related_rooms_wrapper similar_rooms_wrapper pt-0' id='similarpr-tab'>
                    <div className='container'>
                        <h2 className='mb-4'>Similar Products</h2>
                        <div className='row'>
                            <Slider {...productssettings}>
                                {roomsData?.map((item, index) => (
                                    <div className='col-lg-4 col-md-4 col-sm-12' key={index}>
                                        <Products item={item} />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>

                <div class="hotel-details-modal modal fade" id="slidermodal" tabindex="-1" aria-labelledby="slidermodalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header border-0 position-absolute cross-icon">
                                {/* <h5 class="modal-title" id="slidermodalLabel">Modal title</h5> */}
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body border-0 desktop-slider-view p-0">
                                <Slider {...popupssettings}>
                                    {roomsData?.map((item, index) => (
                                        <div className='col-12' key={index}>
                                            <img src={HotelOne} alt='' className='img-fluid w-100' />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default TourDetail