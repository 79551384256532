import i18next from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import enData from './locales/en.json';
import arData from './locales/ar.json';
import timeZonesData from './locales/timeZones.json';

const isSwitch = JSON.parse(localStorage.getItem("isSwitch"));
const LangType = JSON.parse(localStorage.getItem("LangType"));
const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const resources = {
    en: { translation: enData },
    ar: { translation: arData }
};

i18next
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: (isSwitch === null && resources[timeZonesData[currentTimeZone]?.languageCode] !== undefined) ? timeZonesData[currentTimeZone]?.languageCode :
            (isSwitch === null && resources[timeZonesData[currentTimeZone]?.languageCode] === undefined) ? 'en' : LangType,
        debug: true,
        interpolation: { escapeValue: false }
    });

export default i18next;