import React, { useEffect } from 'react'
import LocationCard from '../../shared/LocationCard';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { Center } from '@chakra-ui/react';
import CitiesCard from '../../shared/CitiesCard';
import { connect, useDispatch } from 'react-redux';
import { get_top_hotels_lists } from '../../reduxStore/booking/bookingActions';

const TopCities = ({ cities }) => {
    const arr = [1, 2, 3, 4];
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const settings = {
        dots: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        centerMode: false,
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                },
            },
        ]
    };

    useEffect(() => {
        get_top_hotels_lists(dispatch);
    }, []);

    return (
        <div>
            <div className='top-cities-slider-wrapper'>
                <div className='container'>
                    <h2 className='mb-3'>{t('top_cities')}</h2>
                    {/* {arr.map((item, index) =>
                            <div class="col-lg-3 col-md-6 col-sm-12 cities-col-shadow" key={index}>
                                <LocationCard isHotel={false} />
                            </div>)} */}
                    <Slider {...settings}>
                        {cities?.map((item, index) =>
                            <div class="cities-col-shadow" key={index}>
                                <CitiesCard item={item} />
                            </div>
                        )}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        cities: state.bookings.cities,
    }
};
export default connect(mapStateToProps)(TopCities);