import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { get_vender_id, user_send_otp } from '../reduxStore/User/userActions';
import { getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input';
import { useToast } from '@chakra-ui/react';
import { Form } from 'react-bootstrap';
import PhoneNumber from '../shared/PhoneNumber';

const EnterPhoneNo = ({ user, vendor_id }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const toast = useToast();
    const [formdata, setFormdata] = useState({
        phone_number: ''
    });
    const [country, setCountry] = useState('US');
    const [errors, setErrors] = useState({
        phone_number: '',
    });
    const dispatch = useDispatch();
    const [verifyData, setVerifyData] = useState('');
    let countrycode = getCountryCallingCode(country);

    const handleChange = (label, value) => {
        switch (label) {
            case "phone_number":
                let checkval = `+${countrycode}` + value;
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: !isValidPhoneNumber(checkval) ? "Invalid Phone number" : null }));
                setVerifyData(value);
                break;

            default:
                setFormdata((prev) => ({ ...prev, [label]: value }));
                break;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let checkval = `+${countrycode}` + formdata.phone_number;

        setErrors((prev) => ({
            ...prev,
            phone_number: formdata.phone_number === "" ? "Phone number is required" : !isValidPhoneNumber(checkval) ? "Invalid Phone number" : null
        }));

        if ((formdata.phone_number === "" || !isValidPhoneNumber(checkval))) return;

        const userdata = {
            phoneNo: formdata.phone_number,
            countryCode: `+${countrycode}`
        };

        if (location.pathname === "/admin-verify") {
            userdata.type = "admin";
        } else {
            userdata.type = "user";
        }

        let otpsend = await user_send_otp(userdata, dispatch, toast, vendor_id);
        if (otpsend) {
            localStorage.setItem("verifyData", JSON.stringify(verifyData));

            if (location.pathname === "/admin-verify") {
                navigate("/admin-otp-verify");
            } else {
                navigate("/otp-verify");
            }
        }
    };

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
    }, []);

    return (
        <div className='login-signup-bg-wrapper'>
            <div className='d-grid justify-content-center'>
                <Link className="d-inline-block" to="/login">Back</Link>
                <Form onSubmit={(e) => handleSubmit(e)}>
                    <div className='d-flex flex-column gap-3 mt-2 mb-2'>
                        <div className='col-md-5'>
                            <label className='form-label'>Enter Phone No. : </label>
                            <div>
                                <PhoneNumber
                                    country={country}
                                    setCountry={(val) => setCountry(val)}
                                    phoneNumber={formdata.phone_number}
                                    handleChange={(val) => handleChange('phone_number', val)}
                                />
                                {errors.phone_number && <p className='text-danger'>{errors.phone_number}</p>}
                            </div>
                        </div>
                        <div className='d-flex gap-3 col-md-6'>
                            <button type='submit' className='ylw-theme-button'>Send OTP</button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        vendor_id: state.auth.vendor_id
    }
}
export default connect(mapStateToProps)(EnterPhoneNo);