import React, { useEffect } from 'react'
import { Table } from 'react-bootstrap'
import { connect, useDispatch } from 'react-redux';
import { get_vender_id, get_all_user_lists } from '../../reduxStore/User/userActions';

const UsersList = ({ user, allusers, vendor_id }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (vendor_id) {
            get_all_user_lists(user?.token, dispatch, vendor_id);
        }
    }, [vendor_id]);

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
    }, []);

    return (
        <div>
            <h1>Users List</h1>
            <div className='d-flex flex-column'>
                <div>
                    <Table>
                        <thead>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Address</th>
                            <th>Phone No.</th>
                        </thead>
                        <tbody>
                            {allusers?.map((item, index) => (
                                <tr key={index}>
                                    <td>{item?.name}</td>
                                    <td>{item?.email}</td>
                                    <td>{item?.address}</td>
                                    <td>({item?.country_code}) {item?.phone_number}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        allusers: state.auth.allUsers,
        vendor_id: state.auth.vendor_id
    }
}
export default connect(mapStateToProps)(UsersList);