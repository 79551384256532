import React from 'react'
import FacebookImg from '../../src/assets/facebook-icon.svg';
import LinkdeinImg from '../../src/assets/linkedin-icon.svg';
import InstaImg from '../../src/assets/instagram-icon.svg';
import youtubeImg from '../../src/assets/youtube-icon.svg';
import FooterLogo from '../../src/assets/footer-Logo.svg';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <div className='footer'>
            <div className='container'>
                <div className='footer-grid-wrap'>
                    <div className='footer-logo-box'>
                        <img src={FooterLogo} alt='' />
                        <p className='text-white p-md mt-4'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                    <div className='footer-menu-link'>
                        <h6 className='p-md'>{t('about')}</h6>
                        <ul className='p-0'>
                            <li className=''>
                                <a href="#" className='p-md'>{t('about_booking')}</a>
                            </li>
                            <li className=''>
                                <a href="#" className='p-md'>{t('how_we_work')}</a>
                            </li>
                            <li className=''>
                                <a href="#" className='p-md'>{t('sustainability')}</a>
                            </li>
                            <li className=''>
                                <a href="#" className='p-md'>{t('press_center')}</a>
                            </li>
                            <li className=''>
                                <a href="#" className='p-md'>{t('careers')}</a>
                            </li>
                            <li className=''>
                                <a href="#" className='p-md'>{t('investor_relations')}</a>
                            </li>
                            <li className=''>
                                <a href="#" className='p-md'>{t('corporate_contact')}</a>
                            </li>
                        </ul>
                    </div>
                    <div className='footer-menu-link'>
                        <h6>{t('support')}</h6>
                        <ul className='p-0'>
                            <li className='p-md'>
                                <a href="#" className='p-md'>{t('coronavirus_covid_19')}</a>
                            </li>
                        </ul>
                        <h6>{t('faqs')}</h6>
                        <ul className='p-0'>
                            <li className='p-md'>
                                <a href="#" className='p-md'>{t('privacy_policy')}</a>
                            </li>
                            <li className='p-md'>
                                <a href="#" className='p-md'>{t('contact_us')}</a>
                            </li>
                        </ul>
                    </div>
                    <div className='footer-menu-link'>
                        <h6>{t('terms_and_settings')}</h6>
                        <ul className='p-0'>
                            <li className='p-md'>
                                <a href="#" className='p-md'>{t('privacy_policy')}</a>
                            </li>
                            <li className='p-md'>
                                <a href="#" className='p-md'>{t('terms_and_conditions')}</a>
                            </li>
                            <li className='p-md'>
                                <a href="#" className='p-md'>{t('grievance_officer')}</a>
                            </li>
                            <li className='p-md'>
                                <a href="#" className='p-md'>{t('modern_slavery_statement')}</a>
                            </li>
                            <li className='p-md'>
                                <a href="#" className='p-md'>{t('human_rights_statement')}</a>
                            </li>
                        </ul>
                    </div>
                    <div className='footer-menu-link'>
                        <h6>{t('support')}</h6>
                        <ul className='p-0'>
                            <li className='p-md'>
                                <a href="#" className='p-md'>{t('coronavirus_covid_19')}</a>
                            </li>
                            <li className='p-md'>
                                <a href="#" className='p-md'>{t('faqs')}</a>
                            </li>
                            <li className='p-md'>
                                <a href="#" className='p-md'>{t('privacy_policy')}</a>
                            </li>
                            <li className='p-md'>
                                <a href="#" className='p-md'>{t('modern_slavery_statement')}</a>
                            </li>
                            <li className='p-md'>
                                <a href="#" className='p-md'>{t('human_rights_statement')}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='copyright-wrapper'>
                    <div className='d-flex justify-content-between copyryt-row-grid'>
                        <div className='footer-col-one'>
                            <p className='text-white p-md mb-0'>Copyright©2024 Arab Alkhalej   |   All right reserved </p>
                        </div>
                        <div className='footer-col-two text-end'>
                            <ul className='footer-links p-0 mb-0'>
                                <li className='d-inline-block'>
                                    <a href="https://www.facebook.com/" className='' target='_blank'>
                                        <img src={FacebookImg} alt='' className='d-inline-block' />
                                    </a>
                                </li>
                                <li className='d-inline-block'>
                                    <a href="https://www.linkedin.com/" className='' target='_blank'>
                                        <img src={LinkdeinImg} alt='' className='d-inline-block' />
                                    </a>
                                </li>
                                <li className='d-inline-block circle-bg'>
                                    <a href="https://www.instagram.com/" className='' target='_blank'>
                                        <img src={InstaImg} alt='' className='d-inline-block' />
                                    </a>
                                </li>
                                <li className='d-inline-block circle-bg'>
                                    <a href="https://www.youtube.com/" className='' target='_blank'>
                                        <img src={youtubeImg} alt='' className='d-inline-block' />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer