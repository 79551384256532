import { combineReducers } from "redux";
import userReducer from "./User/userReducer";
import themeReducer from "./themes/themeReducer";
import vendorReducer from "./vendors/vendorReducer";
import { bookingReducer } from "./booking/bookingReducer";
import loaderReducer from "./loader/loaderReducer";


const rootReducer = combineReducers({
    auth: userReducer,
    theme: themeReducer,
    vendor: vendorReducer,
    bookings: bookingReducer,
    loader: loaderReducer
});

export default rootReducer;
