import React from 'react'
import { useNavigate } from 'react-router-dom';
import SelectRoom from '../../assets/ryt-selct-room.png';
import MapImg from '../../assets/map.png';
import UserImg from '../../assets/user.png';
import CalenderImg from '../../assets/calendar.png';
import UserSqImg from '../../assets/person-square .png'

const TourPayment = () => {
    const navigate = useNavigate();
    return (
        <div>

            {/* <button className="ylw-theme-button" onClick={() => navigate('/add-traveller')}>Go To Add Taveller</button> */}
            <div className='payment-details-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8 col-md-7 col-sm-12'>
                            <div className='left-fill-details'>
                                <h2 className='mb-3'>Kolkata Full Day Private Tour on Bike or Car</h2>
                                
                                <div className='travel-payment-wrap border-solid-d'>
                                    <h2 className='mb-3'>Travellers </h2>
                                        <div className='d-flex align-items-center justify-content-between gap-travel-between'>
                                            <div className='d-flex align-items-center align-start-top'>
                                                <div className='travel-top-img'>
                                                    <img src= {UserSqImg} className='me-2'/>
                                                </div>
                                                <div className=''>
                                                    <h3 className='font-family-poppins mb-0'>Traveller 1</h3>
                                                    <p className='p-md mb-0'>*Adult - Should be above 18 years</p>
                                                </div>
                                            </div>
                                            <a href="" className='btn-gradient-purple w-auto p-md' data-bs-toggle="modal" data-bs-target="#travel-popup">Add</a>
                                        </div>

                                        <hr className='border-dotted'/>

                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='d-flex align-items-center align-start-top'>
                                                <div className='travel-top-img'>
                                                    <img src= {UserSqImg} className='me-2'/>
                                                </div>
                                                <div className=''>
                                                    <h3 className='font-family-poppins mb-0'>Traveller 1</h3>
                                                    <p className='p-md mb-0'>*Adult - Should be above 18 years</p>
                                                </div>
                                            </div>
                                            <a href="" className='btn-gradient-purple w-auto p-md' data-bs-toggle="modal" data-bs-target="#travel-popup">Add</a>
                                        </div>
                                </div>
                                
                                <div className='py-4 travel-contact-detail'>
                                    <h2 className='mb-3'>Contact Details</h2>
                                    <div className='form-group form-spacing'>
                                        <label class="form-label p-md text-grey-light">Phone</label>
                                        <input type="tel" className='form-control' placeholder='Enter your phone' />
                                    </div>
                                    <div className='form-group form-spacing'>
                                        <label class="form-label p-md text-grey-light">Location</label>
                                        <input type="text" className='form-control' placeholder='Enter detail' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-5 col-sm-12'>
                            <div className='ryt-room-select-price ryt-tour-payment'>
                                <img src={SelectRoom} alt='' className='img-fluid w-100 top-selct-img' />
                                <h5 className='hd-spacing-room-selct'>Kolkata Full Day Private Tour on Bike or Car</h5>
                                <div class="reviews-box d-flex align-items-baseline mb-2 gap-2">
                                    <p className='d-inline-block p-md mb-0'>4.3</p>
                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                        <li class="d-inline-block"><i class="fas fa-star"></i></li><li class="d-inline-block"><i class="fas fa-star"></i></li>
                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                    </ul>
                                    <p className='d-inline-block p-md mb-0'>(144 reviews)</p>
                                </div>

                                <p className='p-lg mb-2 d-flex align-items-center gap-2'>
                                    <img src={CalenderImg} className='d-inline-block' alt='' />
                                    23 Oct 2024
                                </p>

                                <div className='price-box-room '>
                                    <h5 className='text-purple p-large mb-0'>Price</h5>
                                    <div className='price-line-dotted align-items-end'>
                                        <div className=''>
                                            <p className='p-md mb-0'>Basic Cost</p>
                                            <p className='p-sm mb-0'>2 Adult</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme font-weight-500'>₹1,11,500</p>
                                        </div>
                                    </div>

                                    <div className='price-line-dotted align-items-end'>
                                        <div className=''>
                                            <p className='p-md mb-0'>Fees & Taxes</p>
                                            <p className='p-sm mb-0'>GST 5%</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme font-weight-500'>+ ₹5,575</p>
                                        </div>
                                    </div>

                                    <div className='price-line-dotted align-items-center mb-3 border-0'>
                                        <div className=''>
                                            <p className='p-lg mb-0 font-weight-600 text-color-theme'>Total(incl.VAT)</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-large mb-0 font-weight-600 text-color-theme'>₹1,17,075</p>
                                        </div>
                                    </div>

                                    <button type="submit" className='btn-gradient-purple text-white' onClick={() => navigate('/hotel-booking/card')}>Book Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="add-travel-modal modal fade" id="travel-popup" tabindex="-1" aria-labelledby="travel-popupLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header border-0 cross-icon p-0 align-items-center">
                            <div className='container-fluid travel-detail-header d-flex align-items-center'>
                                <h2 class="modal-title mb-0 lh-1" id="slidermodalLabel">Add Traveller Details</h2>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                        </div>
                        <div class="modal-body border-0 modal-slider-view p-0">
                            <div className='container-fluid'>
                                <form className='left-fill-details'>
                                    <div className='form-group form-spacing'>
                                        <label className='form-label p-lg text-grey-light'>First Name</label>
                                        <input type="text" className='form-control' placeholder='Enter your first name'/>
                                    </div>
                                    <div className='form-group form-spacing'>
                                        <label className='form-label p-lg text-grey-light'>Last Name</label>
                                        <input type="text" className='form-control' placeholder='Enter your first name'/>
                                    </div>
                                    <div className='form-group form-spacing'>
                                        <label className='form-label p-lg text-grey-light'>Gender</label>
                                        <select className='text-grey-light w-100 form-select'>
                                            <option selected>Selct Gender</option>
                                            <option>Female</option>
                                            <option>Male</option>
                                        </select>
                                    </div>
                                    
                                    <div className='form-group form-spacing'>
                                        <label className='form-label p-lg text-grey-light'>Date of  Birth</label>
                                        <div className='d-grid d-grid-calender w-100'>
                                            <select className='text-grey-light w-100 form-select'>
                                                <option selected>DD</option>
                                                <option>DD</option>
                                                <option>DD</option>
                                            </select>
                                            <select className='text-grey-light w-100 form-select'>
                                                <option selected>MM</option>
                                                <option>MM</option>
                                                <option>MM</option>
                                            </select>
                                            <select className='text-grey-light w-100 form-select'>
                                                <option selected>YYYY</option>
                                                <option>YYYY</option>
                                                <option>YYYY</option>
                                            </select>
                                        </div>
                                    </div>    
                                    
                                    <button className='btn-gradient-purple' type='submit'>Add Traveller</button> 
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TourPayment