import React from 'react'
import SelectRoom from '../../assets/ryt-selct-room.png';
import MapImg from '../../assets/map.png';
import UserImg from '../../assets/user.png';
import CalenderImg from '../../assets/calendar.png';
import { useNavigate } from 'react-router-dom';
const HotelBooking = () => {
    const navigate = useNavigate();

    return (
        <div>
            <div className='payment-details-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8 col-md-7 col-sm-12'>
                            <div className='left-fill-details'>
                                <h2 className='mb-3'>Fill your details</h2>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-6 col-sm-12 form-group form-spacing'>
                                        <label class="form-label p-md text-grey-light">First name</label>
                                        <input type="text" className='form-control' placeholder='Enter first name' />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 form-group form-spacing'>
                                        <label class="form-label p-md text-grey-light">Last name</label>
                                        <input type="text" className='form-control' placeholder='Enter last name' />
                                    </div>
                                </div>
                                <div className='form-group form-spacing'>
                                    <label class="form-label p-md text-grey-light">Email</label>
                                    <input type="email" className='form-control' placeholder='Enter your email' />
                                </div>
                                <div className='form-group form-spacing'>
                                    <label class="form-label p-md text-grey-light">Phone</label>
                                    <input type="tel" className='form-control' placeholder='Enter your phone' />
                                </div>

                                <h4>Address</h4>
                                <div className='form-group form-spacing'>
                                    <label class="form-label p-md text-grey-light">House no./Flat no.</label>
                                    <input type="text" className='form-control' placeholder='Enter detail' />
                                </div>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-6 col-sm-12 form-group form-spacing'>
                                        <label class="form-label p-md text-grey-light">State</label>
                                        <input type="text" className='form-control' placeholder='Enter state' />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 form-group form-spacing'>
                                        <label class="form-label p-md text-grey-light">City</label>
                                        <input type="text" className='form-control' placeholder='Enter city' />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 form-group form-spacing'>
                                        <label class="form-label p-md text-grey-light">Pin code</label>
                                        <input type="text" className='form-control' placeholder='Enter code' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-5 col-sm-12'>
                            <div className='ryt-room-select-price'>
                                <img src={SelectRoom} alt='' className='img-fluid w-100 top-selct-img' />
                                <h5 className='hd-spacing-room-selct'>Movenpick Hotel And Residences Riyadh</h5>
                                <div class="reviews-box d-flex align-items-baseline mb-2 gap-2">
                                    <p className='d-inline-block p-md mb-0'>4.3</p>
                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                        <li class="d-inline-block"><i class="fas fa-star"></i></li><li class="d-inline-block"><i class="fas fa-star"></i></li>
                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                        <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                    </ul>
                                    <p className='d-inline-block p-md mb-0'>(144 reviews)</p>
                                </div>
                                <p className='p-lg mb-2 font-size-15 font-weight-500 d-flex align-items-center gap-2'>
                                    <img src={MapImg} className='d-inline-block' alt='' />
                                    Diplomatic Enclave Chanakyapuri
                                </p>

                                <p className='p-lg mb-2 font-size-15 font-weight-500 d-flex align-items-center gap-2'>
                                    <img src={UserImg} className='d-inline-block ' alt='' />
                                    2 Persons
                                </p>

                                <p className='p-lgmb-2 font-size-15 font-weight-500 d-flex align-items-center gap-2'><img src={CalenderImg} className='d-inline-block' alt='' />
                                    23 Oct 2024
                                    <i className="fas fa-arrow-right mx-2"></i>
                                    24 Oct 2024
                                </p>

                                <div className='price-box-room '>
                                    <h5 className='text-purple p-large mb-0'>Price</h5>
                                    <div className='price-line-dotted align-items-end'>
                                        <div className=''>
                                            <p className='p-md mb-0'>Regular Room - Queen Bed</p>
                                            <p className='p-sm mb-0'>1 Room</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme font-weight-500'><strike className='p-sm me-2 text-grey-light font-weight-normal'>$41</strike>$35</p>
                                        </div>
                                    </div>

                                    <div className='price-line-dotted align-items-end'>
                                        <div className=''>
                                            <p className='p-md mb-0'>Regular Room - Queen Bed</p>
                                            <p className='p-sm mb-0'>1 Room</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme font-weight-500'><strike className='p-sm me-2 text-grey-light font-weight-normal'>$41</strike>$35</p>
                                        </div>
                                    </div>

                                    <div className='price-line-dotted price-line-plane align-items-end'>
                                        <div className=''>
                                            <p className='p-md mb-0'>Regular Room - Queen Bed</p>
                                            <p className='p-sm mb-0'>1 Room</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme font-weight-500'><strike className='p-sm me-2 text-grey-light font-weight-normal'>$41</strike>$35</p>
                                        </div>
                                    </div>

                                    <div className='price-line-dotted align-items-center mb-3 border-0 last-font-line'>
                                        <div className=''>
                                            <p className='size-big mb-0 font-weight-600 text-color-theme'>Total(incl.VAT)</p>
                                        </div>
                                        <div className='text-end'>
                                            <p className='p-lg mb-0 text-color-theme size-big font-weight-600'>$137</p>
                                        </div>
                                    </div>

                                    <button type="submit" className='btn-gradient-purple text-white' onClick={() => navigate('/hotel-booking/card')}>Book Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HotelBooking