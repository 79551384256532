// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, OAuthProvider } from "@firebase/auth";
import { getFirestore } from "@firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBAIyQVHSz0YdzoT7na9Fwoo-i89HMfpME",
    authDomain: "fir-project-48620.firebaseapp.com",
    projectId: "fir-project-48620",
    storageBucket: "fir-project-48620.appspot.com",
    messagingSenderId: "464885573893",
    appId: "1:464885573893:web:d910de8ebce2dc799c2f6c",
    measurementId: "G-K2BT4QR7XH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider(app);
googleProvider.addScope('email');

const facebookProvider = new FacebookAuthProvider(app);
facebookProvider.addScope('email');

const appleProvider = new OAuthProvider('apple.com');
appleProvider.addScope('email');

export { db, auth, googleProvider, facebookProvider, appleProvider }
