import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { get_profile_details, get_vender_id, logout, user_token_security_logout } from '../reduxStore/User/userActions';
import BgImg from '../assets/Banner.png';
import { header_theme_design } from '../reduxStore/themes/themeActions';
import LogoImg from '../assets/Logo-White-Yellow.svg';
import { useToast } from '@chakra-ui/react';
import { GET_CURRENCY, IS_EDIT } from '../reduxStore/User/userTypes';
import { signOut } from '@firebase/auth';
import { auth } from '../firebase/firebase';
import { get_all_languages_lists } from '../reduxStore/vendors/vendorActions';
import { useTranslation } from 'react-i18next';
import timeZonesData from '../locales/timeZones.json';
import currenciesData from '../locales/currencies.json';

const CurrencyDropdown = ({ currencies, currency, handleCurrencyChange }) => {
    const dispatch = useDispatch();
    return <div className="currency-translater-header currency-translater-dropdown">
        <div className="dropdown">
            <button className="btn bg-transparent dropdown-toggle dropdown-mine text-white" type="button" id="Currencydropdown" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="far fa-money-bill-alt"></i> <span className='currency_txt'>{currency}</span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMCurrencydropdownenuButton1">
                {currencies?.map(cur => <li key={cur}><a className={`dropdown-item ${currency === cur ? 'active' : ''}`} href="javascript:void(0)" onClick={() => {
                    handleCurrencyChange(cur);
                    localStorage.setItem("toCurrency", JSON.stringify(cur));
                    dispatch({ type: GET_CURRENCY, payload: cur });
                    localStorage.setItem("currencySwitch", JSON.stringify("true"));
                }}>{cur}</a></li>)}
            </ul>
        </div>
    </div>
}

const LanguageDropdown = ({ languages, langLists, language, isMobile, handleLanguageToggle }) => {
    return <div className="currency-translater-header language-translater-dropdown">
        <div className="dropdown">
            <button className="btn bg-transparent dropdown-toggle dropdown-mine text-white" type="button" id="Currencydropdown" data-bs-toggle="dropdown" aria-expanded="false">
                <img src={langLists?.find(l => l?.language === language)?.flag} alt='img not found' style={{ Height: 'auto', width: '25px' }} />
                <span className='currency_txt'>{langLists?.find(l => l?.language === language)?.language}</span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMCurrencydropdownenuButton1">
                {langLists?.map(lang => <li key={lang?.language} className={`d-flex cursor-pointer ${isMobile ? 'mobile-toggle-spacing' : ''} ${lang?.language === language ? 'active-language' : ''}`} onClick={() => {
                    handleLanguageToggle(lang?.language);
                    localStorage.setItem("isSwitch", JSON.stringify("true"));
                }}>
                    <a href="javascript:void(0)" className={`dropdown-item d-flex align-items-center gap-2 ${lang?.language === language ? 'active' : ''}`}>
                        <img src={lang?.flag} alt='img not found' style={{ Height: 'auto', width: '25px' }} />    {lang?.language}
                    </a>
                </li>)}
            </ul>
        </div>
    </div>
}

// const LanguageToggle = ({ languages, language, isMobile, handleLanguageToggle }) => {
//     return <div class="language-translater">
//         <ul class="translator_list d-flex gap-2 m-0">
//             {languages?.map(lang => <li key={lang?.value} class={`text-white p-sm px-2 ${isMobile ? 'mobile-toggle-spacing' : ''} ${lang?.value === language ? 'active-language p-sm' : ''}`} onClick={handleLanguageToggle}>{lang?.label}</li>)}
//         </ul>
//     </div>
// }

const Header = ({ user, userDetails, vendor_id, isEdit, langLists }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();
    const { t, i18n } = useTranslation();
    const [engData, setEngData] = useState(null);
    const [arData, setArData] = useState(null);
    // const currencies = ['USD', 'EUR', 'GBP', 'AUD', 'SAR'];
    const currencies = [
        'USD', 'BRL', 'ARS', 'CAD', 'GBP', 'EUR', 'CHF',
        'SEK', 'DKK', 'NOK', 'PLN', 'RON', 'TRY', 'ZAR', 'EGP',
        'NGN', 'KES', 'AED', 'ILS', 'INR', 'CNY', 'JPY', 'SGD',
        'HKD', 'KRW', 'THB', 'IDR', 'MYR', 'AUD', 'NZD', 'FJD', 'CLP', 'SAR'
    ];
    const languages = [
        { label: 'عربي', value: 'Arabic' },
        { label: 'ENG', value: 'English' },
    ];
    const [language, setLanguage] = useState('');
    const [localLang, setLocalLang] = useState('');
    const [currency, setCurrency] = useState('');
    const [isRTL, setIsRTL] = useState(false);
    const userItems = [
        { name: 'Profile', value: '/profile' },
        { name: 'Change Password', value: '/change-password' },
        { name: 'Logout', value: '' },
    ];
    const vendorItems = [
        { name: 'Logout', value: 'Logout' },
    ];
    const [items, setItems] = useState([]);
    const isSwitch = JSON.parse(localStorage.getItem("isSwitch"));
    const currSwitch = JSON.parse(localStorage.getItem("currencySwitch"));
    const fromCurrency = JSON.parse(localStorage.getItem("fromCurrency"));
    const toCurrency = JSON.parse(localStorage.getItem("toCurrency"));

    const handleLogout = async () => {
        let user_logout = await user_token_security_logout(vendor_id, user?.token, toast);
        if (user_logout) {
            signOut(auth).then(() => { console.log(""); }).catch((error) => { console.log("", error); })
            localStorage.removeItem('userData');
            localStorage.clear();
            dispatch(logout());
            navigate('/');
        }
    };
    // console.log(items, vendor_id);

    useEffect(() => {
        if (user && user?.role === "user") {
            setItems(userItems);
        } else if (user && (user?.role === "superadmin" || user?.role === "Admin" || user?.role === "smallAgency" || user?.role === "mediumAgency")) {
            setItems(vendorItems);
        } else if (!user) {
            setItems([]);
        }
    }, [user]);

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
    }, []);

    useEffect(() => {
        if (user && user?.role === "user") {
            setItems(userItems);
        } else if (user && (user?.role === "superadmin" || user?.role === "Admin" || user?.role === "smallAgency" || user?.role === "mediumAgency" || user?.role === "staff")) {
            setItems(vendorItems);
        } else if (!user) {
            setItems([]);
        }
    }, [user]);

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
    }, []);

    useEffect(() => {
        getUserLocation()
            .then(locationData => {
                const userCountry = locationData.country;
                if (['SA', 'AE', 'EG', 'JO', 'QA', 'OM', 'KW', 'BH', 'LY'].includes(userCountry)) {
                    // setLanguage('Arabic');
                    setIsRTL(true);
                    // setCurrency('SAR');
                } else {
                    // setLanguage('English');
                    setIsRTL(false);
                    // setCurrency('USD');
                }
            })
            .catch(error => console.error('Error fetching location:', error));
    }, []);

    const getUserLocation = async () => {
        try {
            const response = await fetch('https://ipapi.co/json/');
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Failed to fetch geolocation data:', error);
            return { country: 'US' };
        }
    };

    const handleLanguageToggle = (val) => {
        if (val === 'English') {
            setLanguage('English');
            i18n.changeLanguage('en');
            localStorage.setItem("LangType", JSON.stringify('en'));
            setIsRTL(false);
        } else {
            setLanguage('Arabic');
            i18n.changeLanguage('ar');
            localStorage.setItem("LangType", JSON.stringify('ar'));
            setIsRTL(true);
        }
    };

    const handleCurrencyChange = (cur) => {
        setCurrency(cur);
    };

    useEffect(() => {
        const get_Data = async () => {
            await get_profile_details(user?.token, vendor_id, dispatch);
            dispatch({ type: IS_EDIT, payload: false });
        };

        if (isEdit) {
            get_Data();
        }
    }, [isEdit]);

    useEffect(() => {
        if (vendor_id && user) {
            get_profile_details(user?.token, vendor_id, dispatch);
        }
    }, [vendor_id, user]);

    useEffect(() => {
        if (i18n.language === 'ar') {
            document.documentElement.setAttribute('dir', 'rtl');
            document.body.style.textAlign = 'right';
        } else {
            document.documentElement.setAttribute('dir', 'ltr');
            document.body.style.textAlign = 'left';
        }
    }, [i18n.language]);

    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    useEffect(() => {
        let lang_type = JSON.parse(localStorage.getItem("LangType"));

        if (isSwitch) {
            if (lang_type !== "en") {
                setLanguage("Arabic");
                i18n.changeLanguage('ar');
            } else {
                localStorage.setItem("LangType", JSON.stringify('en'));
                setLanguage('English');
                i18n.changeLanguage('en');
            }
        } else {
            const checktime = timeZonesData[currentTimeZone];
            setLanguage(checktime === undefined ? 'English' : (checktime !== undefined && checktime?.languageCode === 'en') ? 'English' : (checktime !== undefined && checktime?.languageCode === 'ar') ? 'Arabic' : 'English');
            i18n.changeLanguage(checktime === undefined ? 'en' : checktime?.languageCode);
            localStorage.setItem("LangType", JSON.stringify(checktime?.languageCode ? checktime?.languageCode : 'en'));
        }

    }, [currentTimeZone]);

    // useEffect(() => {
    //     if (currSwitch) {
    //         setCurrency(toCurrency);
    //     } else {
    //         setCurrency(currenciesData[currentTimeZone])
    //     }
    // }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (!user || user?.role === "user") {
                await get_all_languages_lists(user?.token, dispatch);
            }
        };
        fetchData();
    }, [user]);

    const countryCodes = [
        "AE",
        "SA",
        "LB",
        "IL",
        "BHR",
        "DJI",
        "DZA",
        "EGY",
        "IRN",
        "IRQ",
        "JOR",
        "KWT",
        "LBY",
        "MAR",
        "OMN",
        "QAT",
        "SYR",
        "TUN",
        "YEM"
    ];

    // useEffect(() => {
    //     const fetchData = async () => {
    //         const response = await fetch('https://ipapi.co/json/');
    //         const data = await response.json();
    //         const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${data?.latitude},${data?.longitude}&key=AIzaSyC5JwGGebkSRvbcbWsbg9bZjO7vNhI3loQ`;
    //         const res = await axios.get(url);
    //         console.log("responnnnsnss ====>", res.data.results);
    //         const datas = res.data.results;
    //         const getData = datas.forEach(address => {
    //             const countryCode = address.address_components.find(component =>
    //                 component.types.includes("country")
    //             )?.short_name || "US"; // default to "US" if not found
    //             // console.log("Country", countryCode);

    //             if (countryCodes.includes(countryCode)) {
    //                 setLocalLang('Arabic');
    //             } else {
    //                 setLocalLang('English');
    //             }
    //         });
    //     };
    //     fetchData();
    // }, []);

    useEffect(() => {
        if (currSwitch === null) {
            localStorage.setItem("fromCurrency", JSON.stringify(currenciesData[currentTimeZone] ? currenciesData[currentTimeZone] : 'USD'));
            localStorage.setItem("toCurrency", JSON.stringify(currenciesData[currentTimeZone] ? currenciesData[currentTimeZone] : 'USD'));
            setCurrency(currenciesData[currentTimeZone] ? currenciesData[currentTimeZone] : 'USD');
            dispatch({ type: GET_CURRENCY, payload: currenciesData[currentTimeZone] ? currenciesData[currentTimeZone] : 'USD' });
        } else {
            setCurrency(toCurrency);
            dispatch({ type: GET_CURRENCY, payload: toCurrency });
        }
    }, []);

    return (
        <div>
            <div className="header" style={header_theme_design(BgImg)}>
                <nav className="navbar navbar-expand-lg">
                    <div className="container header-container justify-content-between">
                        <Link className="navbar-brand" to="/">
                            <img src={LogoImg} alt='img not found' />
                        </Link>
                        <div className='desktop_navbar_wrap d-flex gap-4 flex-wrap align-items-center'>
                            <div className='mobile-translator'>
                                {(!user || user?.role === "user") && <LanguageDropdown languages={languages} langLists={langLists} language={language} isMobile={true} handleLanguageToggle={(val) => handleLanguageToggle(val)} />}
                                {/* <LanguageToggle languages={languages} language={language} isMobile={true} handleLanguageToggle={handleLanguageToggle} /> */}
                                <CurrencyDropdown currencies={currencies} currency={currency} handleCurrencyChange={handleCurrencyChange} />
                            </div>

                            <div className='mobile-login-signup'>
                                <div className="dropdown">
                                    <button className="btn user_btn dropdown-toggle dropdown-mine text-white" type="button" id="login_signup" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="bi bi-person"></i>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="login_signup">
                                        {!user ? <>
                                            <li><a className="dropdown-item" href="#"><i class="bi bi-box-arrow-in-right me-2"></i>Login</a></li>
                                            <li><a className="dropdown-item" href="#"><i class="bi bi-person-plus me-2"></i> Signup</a></li>
                                        </>
                                            : <li onClick={handleLogout}><a className="dropdown-item" href="javascript:void(0)">Logout</a></li>}
                                    </ul>
                                </div>
                            </div>

                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="header-menus-wrap collapse navbar-collapse justify-content-end align-items-center" id="navbarScroll">
                                <div className='d-flex desktop-translator align-items-center'>
                                    {(!user || user?.role === "user") && <LanguageDropdown languages={languages} langLists={langLists} language={language} isMobile={true} handleLanguageToggle={(val) => handleLanguageToggle(val)} />}
                                    {/* <LanguageToggle languages={languages} language={language} handleLanguageToggle={handleLanguageToggle} /> */}
                                    <CurrencyDropdown currencies={currencies} currency={currency} handleCurrencyChange={handleCurrencyChange} />
                                </div>
                                <ul className="navbar-nav navbar-nav-scroll align-items-center navbar-header">
                                    <li className="nav-item">
                                        <Link className="nav-link text-white py-0 active" aria-current="page" to="/">{t('support')}</Link>
                                    </li>
                                    {!user && <>
                                        <li className="nav-item">
                                            <Link className="nav-link text-white py-0" to="/login">{t('login')}</Link>
                                        </li>
                                        <li className="nav-item link-line-height">
                                            <Link className="ylw-theme-button d-inline-block" to="/signup">{t('sign_up')}</Link>
                                        </li>
                                    </>}
                                    <li className="nav-item">
                                        <a className="nav-link white-border-btn text-white" href="javascript:void(0)"><img src="/images/Download.png" class="me-2 d-inline-block"></img>{t('get_the_app')}</a>
                                    </li>
                                </ul>
                                {user && <div className="dropdown">
                                    <button className="btn bg-transparent dropdown-toggle dropdown-mine text-white" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="far fa-user me-1"></i> {userDetails?.name}
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        {items.map((item, index) => <>
                                            {item.name === "Logout" ?
                                                <li key={item.name} onClick={handleLogout}>
                                                    <a className="dropdown-item" href="#">{item.name}</a>
                                                </li>
                                                :
                                                <li key={item.name}>
                                                    <Link className="dropdown-item" to={`${item.value}`}>{item.name}</Link>
                                                </li>
                                            }
                                        </>)}
                                    </ul>

                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li onClick={handleLogout}><a className="dropdown-item" href="#">Logout</a></li>
                                    </ul>
                                </div>}
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        userDetails: state.auth.userDetails,
        vendor_id: state.auth.vendor_id,
        isEdit: state.auth.isEdit,
        langLists: state.vendor.langLists
    }
}
export default connect(mapStateToProps)(Header);