import './App.css';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import Header from './components/Header';
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-datepicker/dist/react-datepicker.css";
import Results from './components/Results';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import AuthPages from './auth/AuthPages';
import Login from './auth/Login';
import Signup from './auth/Signup';
import { connect } from 'react-redux';
import ForgotPassword from './auth/ForgotPassword';
import HotelDetail from './components/Details/HotelDetail';
import EnterEmailorPhone from './auth/EnterEmailorPhone';
import VerifyOtp from './auth/VerifyOtp';
import VendorSideBar from './Vendors/Components/VendorSideBar';
import { ProSidebarProvider } from 'react-pro-sidebar';
import UsersList from './Vendors/Components/UsersList';
import Dashboard from './Vendors/Components/Dashboard';
import HotelBooking from './components/Hotels/HotelBooking';
import AdminProfile from './Vendors/Components/AdminProfile';
import AgencyType from './Vendors/Agencies/AgencyType';
import CardDetails from './components/Hotels/CardDetails';
import BookingConfirm from './components/Hotels/BookingConfirm';
import VendorLogin from './auth/VendorLogin';
import BookingFilter from './components/BookingFilter';
import HotelsResult from './components/SearchResult/HotelsResult';
import LocalTourResult from './components/SearchResult/LocalTourResult';
import FlightResult from './components/SearchResult/FlightResult';
import CarResult from './components/SearchResult/CarResult';
import ResetPassword from './auth/ResetPassword';
import UserProfile from './components/UserPages/UserProfile';
import ChangePassword from './components/UserPages/ChangePassword';
import TourDetail from './components/Tours/TourDetail';
import TourPayment from './components/Tours/TourPayment';
import AddTraveller from './components/Tours/AddTraveller';
import VendorProfile from './Vendors/Components/VendorProfile';
import RoundFlight from './components/SearchResult/RoundFlight';
import { useEffect, useState } from 'react';
import Staff from './Vendors/Agencies/Staff';
import VendorDashboard from './Vendors/Components/VendorDashboard';
import StaffDashboard from './Vendors/Components/StaffDashboard';
// import LangHome from './Vendors/Languages/LangHome';
import LanguagesList from './Vendors/Languages/LanguagesList';
import { useTranslation } from 'react-i18next';
import timeZonesData from '../src/locales/timeZones.json';
import EnterPhoneNo from './auth/EnterPhoneNo';
import LoadingSpinner from './LoadingSpinner';
import SendEmail from './auth/SendEmail';

function App({ user }) {
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const isSwitch = JSON.parse(localStorage.getItem("isSwitch"));
  const [width, setWidth] = useState(window.innerWidth);
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    let lang_type = JSON.parse(localStorage.getItem("LangType"));

    if (isSwitch) {
      if (lang_type !== "en") {
        i18n.changeLanguage('ar');
      } else {
        localStorage.setItem("LangType", JSON.stringify('en'));
        i18n.changeLanguage('en');
      }
    } else {
      const checktime = timeZonesData[currentTimeZone];
      i18n.changeLanguage(checktime === undefined ? 'en' : checktime?.languageCode);
      localStorage.setItem("LangType", JSON.stringify(checktime?.languageCode ? checktime?.languageCode : 'en'));
    }

  }, [currentTimeZone]);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };
  const isMobile = width <= 917;

  const UserSideBar = () => {
    return (
      <ProSidebarProvider>
        <VendorSideBar />
      </ProSidebarProvider>
    )
  };

  const BeforeLoginRoutes = () => useRoutes([
    { path: '/', element: <HomePage /> },
    // { path: '/auth', element: <AuthPages isMobile={isMobile} /> },
    { path: '/login', element: <AuthPages isMobile={isMobile} /> },
    { path: '/admin', element: <VendorLogin /> },
    { path: '/staff', element: <VendorLogin /> },
    { path: '/signup', element: <AuthPages  isMobile={isMobile}/> },
    { path: '/reset-password/*', element: <ResetPassword /> },
    { path: '/forgot-password', element: <ForgotPassword /> },
    { path: '/password-reset-email', element: <SendEmail /> },
    { path: '/user-verify', element: <EnterPhoneNo /> },
    { path: '/otp-verify', element: <VerifyOtp /> },
    { path: '/admin-verify', element: <EnterPhoneNo /> },
    { path: '/admin-otp-verify', element: <VerifyOtp /> },
    { path: '/detail', element: <HotelDetail /> },
    { path: '/hotel-booking/payment', element: <HotelBooking /> },
    { path: '/hotel-booking/card', element: <CardDetails /> },
    { path: '/hotel-booking/confirm', element: <BookingConfirm /> },
    { path: '/search-results', element: <Results /> },
    { path: '/hotel-results', element: <HotelsResult /> },
    { path: '/local-tours-results', element: <LocalTourResult /> },
    { path: '/tour-detail', element: <TourDetail /> },
    { path: '/tour-payment', element: <TourPayment /> },
    { path: '/add-traveller', element: <AddTraveller /> },
    { path: '/flight-results', element: <FlightResult /> },
    { path: '/round-trip-results', element: <RoundFlight /> },
    { path: '/car-results', element: <CarResult /> },
    { path: '*', element: <Navigate to="/" replace /> }
  ]);

  const AfterLoginRoutes = () => useRoutes([
    { path: '/', element: <HomePage /> },
    { path: '/profile', element: <UserProfile /> },
    { path: '/change-password', element: <ChangePassword /> },
    { path: '/detail', element: <HotelDetail /> },
    { path: '/hotel-booking/payment', element: <HotelBooking /> },
    { path: '/hotel-booking/card', element: <CardDetails /> },
    { path: '/hotel-booking/confirm', element: <BookingConfirm /> },
    { path: '/search-results', element: <Results /> },
    { path: '/hotel-results', element: <HotelsResult /> },
    { path: '/local-tours-results', element: <LocalTourResult /> },
    { path: '/tour-detail', element: <TourDetail /> },
    { path: '/tour-payment', element: <TourPayment /> },
    { path: '/add-traveller', element: <AddTraveller /> },
    { path: '/flight-results', element: <FlightResult /> },
    { path: '/round-trip-results', element: <RoundFlight /> },
    { path: '/car-results', element: <CarResult /> },
    { path: '*', element: <Navigate to="/" replace /> }
  ]);

  const AdminRoutes = () => useRoutes([
    { path: '/', element: <Dashboard /> },
    { path: '/users', element: <UsersList /> },
    { path: '/agencies', element: <AgencyType /> },
    { path: '/profile', element: <AdminProfile /> },
    { path: '/languages', element: <LanguagesList /> },
    { path: '*', element: <Navigate to="/" replace /> }
  ]);

  const VendorAgenciesRoutes = () => useRoutes([
    { path: '/', element: <VendorDashboard /> },
    { path: '/staff', element: <Staff /> },
    { path: '/profile', element: <VendorProfile /> },
    { path: '/change-password', element: <ChangePassword /> },
    { path: '*', element: <Navigate to="/" replace /> }
  ]);

  const StaffRoutes = () => useRoutes([
    { path: '/', element: <StaffDashboard /> },
    { path: '/profile', element: <VendorProfile /> },
    { path: '/change-password', element: <ChangePassword /> },
    { path: '*', element: <Navigate to="/" replace /> }
  ]);

  const paths = [
    "/signup",
    "/login",
    "/detail",
    "/user-verify",
    "/otp-verify",
    "/admin",
    "/admin-verify",
    "/forgot-password",
    "/reset-password",
    "/profile",
    "/change-password",
    "/staff",
    "/hotel-booking/card",
    "/password-reset-email"
  ];

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.addEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <LoadingSpinner />
      <Header />
      {((!user || user.role === "user") && !paths.includes(location.pathname)) && <BookingFilter />}
      {user && user.role === "superadmin" ?
        <>
          <div className="d-flex">
            <UserSideBar />
            <div className="home-page w-100">
              <AdminRoutes />
            </div>
          </div>
        </>
        : user && (user.role === "smallAgency" || user.role === "mediumAgency") ?
          <>
            <div className="d-flex">
              <UserSideBar />
              <div className="home-page w-100">
                <VendorAgenciesRoutes />
              </div>
            </div>
          </>
          : user && user.role === "staff" ?
            <>
              <div className="d-flex">
                <UserSideBar />
                <div className="home-page w-100">
                  <StaffRoutes />
                </div>
              </div>
            </>
            : user && user.role === "user" ?
              <AfterLoginRoutes />
              : <BeforeLoginRoutes />
      }
      <Footer />
    </>
  );
}



const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  }
}
export default connect(mapStateToProps)(App);
