import React from 'react'
import { useNavigate } from 'react-router-dom'
import ResultOne from '../../../src/assets/result-img.png'
import AirCondition from '../../assets/l-ac.png'
import BanqueRimg from '../../assets/banquet-1.png'
import GardenHotel from '../../assets/gardenS.png'
import PoolHotel from '../../assets/poolL.png'
import WifiHotel from '../../assets/wifi-net.png'
import GymHotel from '../../assets/gymM.png'
import ParkingHotel from '../../assets/parking.png'
import BuffetHotel from '../../assets/buffet.png'
import BeachHotel from '../../assets/beach-near.png'
import BarsHotel from '../../assets/barss.png'
import BarberHotel from '../../assets/barber-s.png'
import AtmDetail from '../../assets/atm-l.png'
import ReceptionsIMG from '../../assets/receptions.png'
import CafeHotel from '../../assets/cafe.png'

const LocalTourResult = () => {
    const navigate = useNavigate();

    return (
        <div>
            <div className='srch-result-wrapper'>
                <div className='container'>
                    <div class="row align-items-start">
                        <div className='col-lg-3 col-md-12 col-sm-12'>
                            <div className='left-filter-wrapper'>
                                <div className='d-flex align-items-center justify-content-between bottom-border-filter'>
                                    <h3 className='mb-0'>Filter</h3>
                                    <p className='p-sm mb-0 text-purple font-weight-600'>Clear Filter</p>
                                </div>
                                <div className=''>
                                    <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingOne">
                                                <button class="accordion-button collapsed acoordian-mine-button p-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    Category
                                                </button>
                                            </h2>
                                            <div id="flush-collapseOne" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body p-0">
                                                    <form className='filter-checkobx-form'>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault"/>
                                                            <label className="form-check-label lh-1 Time p-md text-grey-light" for="flexCheckDefault">
                                                                Category 1
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault"/>
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                Category 2
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault"/>
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                Category 3
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault"/>
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                Category 4
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault"/>
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                Category 5
                                                            </label>
                                                        </div>  

                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault"/>
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                Category 6
                                                            </label>
                                                        </div>

                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault"/>
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                Category 7
                                                            </label>
                                                        </div> 

                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault"/>
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                Category 8
                                                            </label>
                                                        </div> 
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingrating">
                                                <button class="accordion-button collapsed acoordian-mine-button p-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapserating" aria-expanded="false" aria-controls="flush-collapserating">
                                                    Rating
                                                </button>
                                            </h2>
                                            <div id="flush-collapserating" class="accordion-collapse collapse show" aria-labelledby="flush-headingrating" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body p-0">
                                                    <form className='filter-checkobx-form'>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault"/>
                                                            <label className="form-check-label lh-1" for="flexCheckDefault">
                                                                <div class="reviews-box d-flex align-items-center mb-2">
                                                                    <p className='d-inline-block p-md mb-0 me-1 lh-1'>5</p>
                                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>

                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>

                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>

                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>

                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>
                                                                    </ul>
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault"/>
                                                            <label className="form-check-label lh-1" for="flexCheckDefault">
                                                                <div class="reviews-box d-flex align-items-center mb-2">
                                                                    <p className='d-inline-block p-md mb-0 me-1 lh-1'>4</p>
                                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>

                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>

                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>
                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>
                                                                    </ul>
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault"/>
                                                        
                                                            <label className="form-check-label lh-1" for="flexCheckDefault">
                                                                <div class="reviews-box d-flex align-items-center mb-2">
                                                                    <p className='d-inline-block p-md mb-0 me-1 lh-1'>3</p>
                                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>

                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>

                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>
                                                                    </ul>
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault"/>

                                                            <label className="form-check-label lh-1" for="flexCheckDefault">
                                                                <div class="reviews-box d-flex align-items-center mb-2">
                                                                <p className='d-inline-block p-md mb-0 me-1 lh-1'>2</p>
                                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>
                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>
                                                                    </ul>
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault"/>
                                                            <label className="form-check-label lh-1" for="flexCheckDefault">
                                                                <div class="reviews-box d-flex align-items-center mb-2">
                                                                    <p className='d-inline-block p-md mb-0 me-1 lh-1'>1</p>
                                                                    <ul class="d-inline-block star-rating p-0 mb-0">
                                                                        <li class="d-inline-block p-sm lh-1"><i class="fas fa-star"></i></li>
                                                                    </ul>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingOne">
                                                <button class="accordion-button collapsed acoordian-mine-button p-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTime" aria-expanded="false" aria-controls="flush-collapseTime">
                                                    Time of day
                                                </button>
                                            </h2>
                                            <div id="flush-collapseTime" class="accordion-collapse collapse show" aria-labelledby="flush-headingTime" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body p-0">
                                                    <form>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault"/>
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                Morning 
                                                                <span className='d-block p-all-small'>Starts before 12:00 PM</span>
                                                            </label>
                                                        </div>   
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault"/>
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                Afternoon 
                                                                <span className='d-block p-all-small'>Starts after 12:00 PM</span>
                                                            </label>
                                                        </div> 
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault"/>
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                Evening and night  
                                                                <span className='d-block p-all-small'>Starts after 06:00 PM</span>
                                                            </label>
                                                        </div>  
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingOne">
                                                <button class="accordion-button collapsed acoordian-mine-button p-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTime" aria-expanded="false" aria-controls="flush-collapseTime">
                                                    Language
                                                </button>
                                            </h2>
                                            <div id="flush-collapseTime" class="accordion-collapse collapse show" aria-labelledby="flush-headingTime" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body p-0">
                                                    <form>
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault"/>
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                English
                                                            </label>
                                                        </div>   
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault"/>
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                French 
                                                            </label>
                                                        </div> 
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault"/>
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                German
                                                            </label>
                                                        </div>  
                                                        <div class="form-check">
                                                            <input className="form-check-input form-check-border" type="checkbox" value="" id="flexCheckDefault"/>
                                                            <label className="form-check-label lh-1 text-grey-light p-md" for="flexCheckDefault">
                                                                Spanish
                                                            </label>
                                                        </div> 
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>       
                        </div>
                        <div className="col-lg-9 col-md-12 col-sm-12">
                            <div className='ryt-resultshow-wrapper'>
                                <h3 className='text-grey-light spacing-result-txt mb-0'>Showing result for <span className='font-weight-600 text-color-theme'>“Kolkata”</span></h3>
                                        
                                <div className='w-100 d-grid grid-resultshow'>
                                    <div className='result-img-rounded'>
                                        <img src= {ResultOne} alt=''/>
                                    </div>
                                
                                    <div className='srch-details-box'>
                                        <h3 className='mb-0'>Kolkata Full Day Private Tour on Bike or Car</h3>
                                        <div class="reviews-box d-flex align-items-center mb-1">
                                            <p className='d-inline-block text-grey-light  p-all-small mb-0 me-2'>4.3</p>
                                            <ul class="d-inline-block star-rating p-0 mb-0">
                                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                
                                                <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>
                                
                                                <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>
                                
                                                <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>
                                
                                            </ul>
                                            <p className='d-inline-block p-all-small text-grey-light mb-0 ms-2'>(144 reviews)</p>
                                        </div>
                                        <p className='p-md'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>   
                                    </div>
                                    <div className='srch-price-box'>
                                        <div className='inner-srch-spacing'>
                                            <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                            <p className='p-md'>(incl.VAT)</p>
                                                <a className='btn btn-gradient-purple text-white d-inline-block h-auto w-100' onClick={() => navigate('/tour-detail')}>Book Now</a>
                                        </div>       
                                    </div>
                                </div>

                                <div className='w-100 d-grid grid-resultshow'>
                                    <div className='result-img-rounded'>
                                        <img src= {ResultOne} alt=''/>
                                    </div>
                                
                                    <div className='srch-details-box'>
                                        <h3 className='mb-0'>Kolkata Full Day Private Tour on Bike or Car</h3>
                                        <div class="reviews-box d-flex align-items-center mb-1">
                                            <p className='d-inline-block text-grey-light  p-all-small mb-0 me-2'>4.3</p>
                                            <ul class="d-inline-block star-rating p-0 mb-0">
                                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                
                                                <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>
                                
                                                <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>
                                
                                                <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>
                                
                                            </ul>
                                            <p className='d-inline-block p-all-small text-grey-light mb-0 ms-2'>(144 reviews)</p>
                                        </div>
                                        <p className='p-md'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>   
                                    </div>
                                    <div className='srch-price-box'>
                                        <div className='inner-srch-spacing'>
                                            <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                            <p className='p-md'>(incl.VAT)</p>
                                                <a className='btn btn-gradient-purple text-white d-inline-block h-auto w-100' onClick={() => navigate('/tour-detail')}>Book Now</a>
                                        </div>       
                                    </div>
                                </div>

                                <div className='w-100 d-grid grid-resultshow'>
                                    <div className='result-img-rounded'>
                                        <img src= {ResultOne} alt=''/>
                                    </div>
                                
                                    <div className='srch-details-box'>
                                        <h3 className='mb-0'>Kolkata Full Day Private Tour on Bike or Car</h3>
                                        <div class="reviews-box d-flex align-items-center mb-1">
                                            <p className='d-inline-block text-grey-light  p-all-small mb-0 me-2'>4.3</p>
                                            <ul class="d-inline-block star-rating p-0 mb-0">
                                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                
                                                <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>
                                
                                                <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>
                                
                                                <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>
                                
                                            </ul>
                                            <p className='d-inline-block p-all-small text-grey-light mb-0 ms-2'>(144 reviews)</p>
                                        </div>
                                        <p className='p-md'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>   
                                    </div>
                                    <div className='srch-price-box'>
                                        <div className='inner-srch-spacing'>
                                            <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                            <p className='p-md'>(incl.VAT)</p>
                                                <a className='btn btn-gradient-purple text-white d-inline-block h-auto w-100' onClick={() => navigate('/tour-detail')}>Book Now</a>
                                        </div>       
                                    </div>
                                </div>

                                <div className='w-100 d-grid grid-resultshow'>
                                    <div className='result-img-rounded'>
                                        <img src= {ResultOne} alt=''/>
                                    </div>
                                
                                    <div className='srch-details-box'>
                                        <h3 className='mb-0'>Kolkata Full Day Private Tour on Bike or Car</h3>
                                        <div class="reviews-box d-flex align-items-center mb-1">
                                            <p className='d-inline-block text-grey-light  p-all-small mb-0 me-2'>4.3</p>
                                            <ul class="d-inline-block star-rating p-0 mb-0">
                                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                
                                                <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>
                                
                                                <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>
                                
                                                <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>
                                
                                            </ul>
                                            <p className='d-inline-block p-all-small text-grey-light mb-0 ms-2'>(144 reviews)</p>
                                        </div>
                                        <p className='p-md'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>   
                                    </div>
                                    <div className='srch-price-box'>
                                        <div className='inner-srch-spacing'>
                                            <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                            <p className='p-md'>(incl.VAT)</p>
                                                <a className='btn btn-gradient-purple text-white d-inline-block h-auto w-100' onClick={() => navigate('/tour-detail')}>Book Now</a>
                                        </div>       
                                    </div>
                                </div>

                                <div className='w-100 d-grid grid-resultshow'>
                                    <div className='result-img-rounded'>
                                        <img src= {ResultOne} alt=''/>
                                    </div>
                                
                                    <div className='srch-details-box'>
                                        <h3 className='mb-0'>Kolkata Full Day Private Tour on Bike or Car</h3>
                                        <div class="reviews-box d-flex align-items-center mb-1">
                                            <p className='d-inline-block text-grey-light  p-all-small mb-0 me-2'>4.3</p>
                                            <ul class="d-inline-block star-rating p-0 mb-0">
                                                <li class="d-inline-block"><i class="fas fa-star"></i></li>
                                
                                                <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li><li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>
                                
                                                <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>
                                
                                                <li class="d-inline-block p-sm"><i class="fas fa-star"></i></li>
                                
                                            </ul>
                                            <p className='d-inline-block p-all-small text-grey-light mb-0 ms-2'>(144 reviews)</p>
                                        </div>
                                        <p className='p-md'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>   
                                    </div>
                                    <div className='srch-price-box'>
                                        <div className='inner-srch-spacing'>
                                            <p className='d-inline-block size-big mb-0 tab-0-content-sp'><span className='yellow-big-txt'>$55</span><strike>$66</strike></p>
                                            <p className='p-md'>(incl.VAT)</p>
                                            <a className='btn btn-gradient-purple text-white d-inline-block h-auto w-100' onClick={() => navigate('/tour-detail')}>Book Now</a>
                                        </div>       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>        
                </div>
            </div>
        </div>
    )
}

export default LocalTourResult