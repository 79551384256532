import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { apply_language_active, get_all_languages_lists } from '../../reduxStore/vendors/vendorActions';

const LanguagesList = ({ user, langLists }) => {
    // const [languages, setLanguages] = useState([]);
    const dispatch = useDispatch();

    // useEffect(() => {
    //     const fetchLanguages = async () => {
    //         const res = await axios.get('https://restcountries.com/v3.1/all');
    //         const uniqueLang = new Set();
    //         res.data.forEach(country => {
    //             if (country.languages) {
    //                 Object.values(country.languages).forEach(lang => {
    //                     uniqueLang.add(lang);
    //                 })
    //             }
    //         });
    //         console.log("uniquess", uniqueLang);
    //         setLanguages(Array.from(uniqueLang));
    //         console.log(res.data);
    //     };
    //     fetchLanguages();
    // }, []);

    // const structuredLanguages = languages.map(lang => ({
    //     key: lang,
    //     value: false
    // }));

    // console.log(structuredLanguages);

    useEffect(() => {
        const fetchData = async () => {
            await get_all_languages_lists(user?.token, dispatch, "all");
        };
        fetchData();
    }, []);

    const handleToogle = async (item) => {
        const data = {
            id: item?._id,
            language: item?.language,
            value: item?.value === true ? false : true,
        };
        let isapplied = await apply_language_active(user?.token, data);
        if (isapplied) await get_all_languages_lists(user?.token, dispatch, "all");
    };


    return (
        <div className='mb-3'>
            <h1>Languages List</h1>
            <div className='d-flex flex-wrap gap-3'>
                {langLists.map((item, index) => (
                    <div key={index} className='d-flex col-md-2 align-items-center'>
                        <img src={item?.flag} alt='img not found' style={{ maxHeight: '19px' }} />
                        <span className='me-2'>{item?.language}</span>
                        <input type="checkbox" checked={item?.value} onClick={() => handleToogle(item)} />
                    </div>
                ))}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        vendor_id: state.auth.vendor_id,
        langdata: state.vendor.langdata,
        langLists: state.vendor.langLists
    }
}
export default connect(mapStateToProps)(LanguagesList);