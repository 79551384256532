import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import AddAgency from '../../Modals/AddAgency';
import { connect, useDispatch } from 'react-redux';
import { get_all_medium_agencies, get_all_small_agencies } from '../../reduxStore/vendors/vendorActions';
import { get_profile_details, get_vender_id } from '../../reduxStore/User/userActions';

const AgencyType = ({ user, smallagencies, mediumagencies, vendor_id }) => {
    const [isShow, setIsShow] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        get_all_small_agencies(user?.token, dispatch, vendor_id);
        get_all_medium_agencies(user?.token, dispatch, vendor_id);
    }, []);

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
        if (vendor_id) {
            get_profile_details(user?.token, vendor_id, dispatch);
        }
    }, [vendor_id]);

    return (
        <div>
            <h1>Agencies</h1>
            <div className='d-flex flex-column'>
                <div className='flex-end'>
                    <button className='ylw-theme-button' onClick={() => setIsShow(true)}>+ Add New</button>
                </div>
                <div>
                    <h4>Agency Type 1</h4>
                    <Table>
                        <thead>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Address</th>
                            <th>Phone No.</th>
                        </thead>
                        <tbody>
                            {smallagencies.map((item, index) => (
                                <tr key={index}>
                                    <td>{item?.name}</td>
                                    <td>{item?.email}</td>
                                    <td>{item?.address}</td>
                                    <td>({item?.country_code}) {item?.phone_number}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div>
                    <h4>Agency Type 2</h4>
                    <Table>
                        <thead>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Address</th>
                            <th>Phone No.</th>
                        </thead>
                        <tbody>
                            {mediumagencies.map((item, index) => (
                                <tr key={index}>
                                    <td>{item?.name}</td>
                                    <td>{item?.email}</td>
                                    <td>{item?.address}</td>
                                    <td>({item?.country_code}) {item?.phone_number}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
            <AddAgency show={isShow} handleClose={() => setIsShow(false)} />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        vendor_id: state.auth.vendor_id,
        smallagencies: state.vendor.small_agencies,
        mediumagencies: state.vendor.medium_agencies,
    }
}
export default connect(mapStateToProps)(AgencyType);