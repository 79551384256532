import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { connect, useDispatch } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { get_vender_id, reset_password } from '../reduxStore/User/userActions';
// import LogoImg from '../assets/projectlogo.png';
import { useToast } from "@chakra-ui/react";

const ResetPassword = ({ vendor_id }) => {
  let [searchParams] = useSearchParams();
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: ''
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    confirmPassword: null,
    password: null,
  });
  const toast = useToast();
  const useremail = JSON.parse(localStorage.getItem("verifyEmail"));
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const handleChange = (value, label) => {
    errors[label] = !value ? `required` : ((label === 'password') && (value.length <= 5)) ? 'invalid' : ((label === 'confirmPassword') && (formData?.password !== value)) ? 'mismatch' : null;
    setErrors({ ...errors });
    setFormData({ ...formData, [label]: value });
  };

  const checkErrors = () => {
    let err = false;
    const entriesArray = Object.entries(formData);
    entriesArray.forEach(([key, value]) => {
      err = (!value || ((key === 'password') && (value.length <= 5)) || ((key === 'confirmPassword') && (formData?.password !== value))) ? true : false;
      handleChange(value, key);
    });
    return err;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (checkErrors()) {
      return;
    }
    const data = {
      password: formData?.password,
      vendor_id: vendor_id,
    }
    await reset_password(data, searchParams.get('email'), searchParams.get('token'), vendor_id, dispatch, navigate, toast);
  };

  useEffect(() => {
    get_vender_id("superadmin", dispatch);
  }, []);


  return (
    <div className="login-wrapper d-flex justify-content-center align-items-center">
      <div className="text-center">
        <Form className="auth-form my-4" onSubmit={handleSubmit}>
          <h2>Reset Password</h2>
          <div className="form-group">
            <label className="form-label">Password</label>
            <input
              name="password"
              className="form-control"
              placeholder="Enter password"
              type={showPassword ? "text" : "password"}
              onChange={(e) => handleChange(e.target.value, "password")}
            />
            {!showPassword ? (
              <i class="bi bi-eye-slash"
                color="black"
                size={20}
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              <i class="bi bi-eye"
                color="black"
                size={20}
                onClick={() => setShowPassword(!showPassword)}
              />
            )}
            {errors.password && (
              <p style={{ color: "red" }}>
                {errors.password === 'required' ? 'Password is required.' : 'Password length should be greater than 5.'}
              </p>
            )}
          </div>
          <div className="form-group">
            <label className="form-label">Confirm Password</label>
            <input
              name="confirmPassword"
              className="form-control"
              placeholder="Enter confirm password"
              type={showCPassword ? "text" : "password"}
              onChange={(e) => handleChange(e.target.value, "confirmPassword")}
            />
            {!showCPassword ? (
              <i class="bi bi-eye-slash"
                color="black"
                size={20}
                onClick={() => setShowCPassword(!showCPassword)}
              />
            ) : (
              <i class="bi bi-eye"
                color="black"
                size={20}
                onClick={() => setShowCPassword(!showCPassword)}
              />
            )}
            {errors.confirmPassword && (
              <p style={{ color: "red" }}>
                {errors.confirmPassword === 'required' ? 'Confirm Password is required.' : 'Confirm Password must match.'}
              </p>
            )}
          </div>
          <button type="submit" className="ylw-theme-button w-100 my-2">
            Reset
          </button>
          <Link to="/login" className='text-decoration-none my-2 h5 link-color'>
            Go to Login
          </Link>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    vendor_id: state.auth.vendor_id
  }
}
export default connect(mapStateToProps)(ResetPassword);