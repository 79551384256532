import axios from "axios";
import { GET_CITIES_LISTS, GET_HOTELS_LISTS, GET_TOP_HOTELS } from "./bookingTypes";
import { start_loading, stop_loading } from "../loader/loaderActions";


const { REACT_APP_OTA_HOTEL_URL } = process.env;

export const get_top_hotels_lists = async (dispatch) => {
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_HOTEL_URL}Hotel/hotelList`;
        const res = await axios.post(url);
        if (res.data && res.data.status) {
            dispatch({ type: GET_TOP_HOTELS, payload: res.data.data });
            dispatch({ type: GET_CITIES_LISTS, payload: res.data.cities });
        }
    } catch (error) {
        console.log("Error in Hotels Lists", error);
    } finally {
        dispatch(stop_loading());
    }
};

export const get_search_hotels_lists = async (dispatch) => {
    dispatch(start_loading());
    try {
        const url = `${REACT_APP_OTA_HOTEL_URL}Hotel/hotels`;
        const res = await axios.post(url);
        if (res.data && res.data.status) {
            dispatch({ type: GET_HOTELS_LISTS, payload: [...res.data.result, ...res.data.result] });
        }

    } catch (error) {
        console.log("Error in Hotels Lists", error);
    } finally {
        dispatch(stop_loading());
    }
};
