import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { get_vender_id, login_user } from '../reduxStore/User/userActions';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import OTALogin from '../shared/OTALogin';

const VendorLogin = ({ user, vendor_id }) => {
    const [formdata, setFormdata] = useState({
        email: '',
        password: '',
    });

    const [errors, setErrors] = useState({
        email: '',
        password: '',
    });
    const location = useLocation();
    console.log(location);

    const exptest = /^(?!.*\.\.)(?!.*-@)(?!.*#)([A-Z0-9._%+-]+)@([A-Z0-9.-]+\.[A-Z]{2,})$/i;
    const [isShow, setIsShow] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();

    const handleChange = (label, value) => {
        switch (label) {
            case "email":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Email is required" : !exptest.test(value) ? "Invalid email" : null }));
                break;
            case "password":
                setFormdata((prev) => ({ ...prev, [label]: value }));
                setErrors((prev) => ({ ...prev, [label]: value === "" ? "Password is required" : value.length <= 5 ? "Password should contain more than 5characters" : null }));
                break;

            default:
                setFormdata((prev) => ({ ...prev, [label]: value }));
                break;
        }
    };

    const handleValid = () => {
        let err = false;

        const Errors = {
            email: formdata.email === "" ? "Email is required" : !exptest.test(formdata.email) ? "Invalid email" : null,
            password: formdata.password === "" ? "Password is required" : formdata.password.length <= 5 ? "Password should contain more than 5 characters" : null,
        };

        let newErrors = Object.entries(Errors);
        newErrors.forEach(([key, value]) => {
            if (value) {
                err = true;
                setErrors((prev) => ({ ...prev, [key]: value }));
            }
        });

        return err;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (handleValid()) return;

        const userdata = { username: formdata.email, password: formdata.password, type: location.pathname === "/staff" ? 'staff' : 'admin' };
        // console.log("formdata submit --->", formdata);
        await login_user(userdata, dispatch, toast, navigate, vendor_id);
    };

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
    }, []);

    // console.log(user, vendor_id);

    return (
        <div className='login-signup-bg-wrapper'>

            <div className='d-grid justify-content-center'>
                <h1 className=''>Login</h1>
                <OTALogin
                    formdata={formdata}
                    errors={errors}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    isShow={isShow}
                    setIsShow={() => setIsShow(!isShow)}
                />
                <div class="d-flex gap-2">
                    <span>Doesn't have an account!</span>
                    <Link to="/signup">Register</Link>
                </div>
                <p className='text-center'>or</p>
                <div class="d-flex gap-2">
                    <Link className="ylw-theme-button d-inline-block" to="/admin-verify">Login with OTP</Link>
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        vendor_id: state.auth.vendor_id
    }
}
export default connect(mapStateToProps)(VendorLogin);