import React from 'react'

const StaffDashboard = () => {
    return (
        <div>
            <h1>Staff Dashboard</h1>
        </div>
    )
};

export default StaffDashboard;