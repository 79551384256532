import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import OTPInput from "react-otp-input";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { verify_email_otp } from "../reduxStore/User/userActions";
import { connect, useDispatch } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { get_vender_id, login_user } from "../reduxStore/User/userActions";

const VerifyOtp = ({ vendor_id }) => {
    const [userOTP, setUserOTP] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const toast = useToast();
    const useremail = JSON.parse(localStorage.getItem("verifyData"));

    const handleChange = (otp) => {
        setUserOTP(otp);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (userOTP !== '') {
            const user_data = {
                username: useremail,
                otp: Number(userOTP),
                login_with_otp: true,
                type: location.pathname === "/admin-otp-verify" ? "admin" : "user"
            };
            await login_user(user_data, dispatch, toast, navigate, vendor_id);
        }
    };

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
    }, []);

    return (
        <div className="login-wrapper d-flex justify-content-center align-items-center">
            <Container>
                <Row className="justify-content-center">
                    <Col md={5}>
                        <div className="text-center">
                            <Link to="/" className='mb-4'>
                                {/* <img src={LogoImg} alt='bankd-logo' height={70} /> */}
                            </Link>

                            <Form className="auth-form mt-4" onSubmit={handleSubmit}>
                                <h2>Enter OTP Code</h2>
                                <div className="col-md-12 col-lg-12">
                                    <OTPInput
                                        value={userOTP}
                                        onChange={handleChange}
                                        numInputs={6}
                                        renderSeparator={<span></span>}
                                        renderInput={(props) => <input {...props} />}
                                        inputStyle={{
                                            width: '2.5rem',
                                            height: '2.5rem',
                                            margin: '0 0.5rem',
                                            fontSize: '1.5rem',
                                            borderRadius: 4,
                                            border: '1px solid rgba(0,0,0,0.3)',
                                        }}
                                    />
                                </div>
                                <button type="submit" className="ylw-theme-button w-100 my-2">
                                    Verify
                                </button>
                                <Link to="/user-verify" className='text-decoration-none mb-2 h5 link-color'>
                                    Back
                                </Link>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        vendor_id: state.auth.vendor_id
    }
}
export default connect(mapStateToProps)(VerifyOtp);
