import { Height } from '@mui/icons-material';
import React from 'react'
import { Overlay, Popover } from 'react-bootstrap';


const SelectPopOver = ({ id, open, anchorEl, setAnchorEl, optionsData, setOptionsData }) => {

    const handleData = (label, key) => {
        setOptionsData((prev) => ({
            ...prev,
            [label]: key === '+' ? prev[label] + 1 : prev[label] - 1,
        }));
    };

    return (
        <Overlay target={anchorEl} show={open} placement="bottom" onHide={() => setAnchorEl(null)} rootClose>
            {(props) => (
                <Popover {...props} id={id}>
                    <Popover.Body>
                        <div style={{ width: '20rem' }}>
                            {["Room", "Adults", "Children", "Pets"].map((label, index) => (
                                <div key={index} className="d-flex justify-content-between align-items-center mb-3">
                                    <span className="col-6">{label}</span>
                                    <div className="col-6 d-flex  gap-2">
                                        <button className='rooms-qty-btn' onClick={() => handleData(label, '-')}>-</button>
                                        <span className='rooms-qty-text'>{optionsData[label]}</span>
                                        <button className='rooms-qty-btn' onClick={() => handleData(label, '+')}>+</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Popover.Body>
                </Popover>
            )}
        </Overlay>

    )
}

export default SelectPopOver