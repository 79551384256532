import React, { useEffect, useState } from 'react'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { get_vender_id, logout, user_token_security_logout } from '../../reduxStore/User/userActions';
import { useToast } from '@chakra-ui/react';

const VendorSideBar = ({ user, vendor_id }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToast();

    const handleLogout = async () => {
        let user_logout = await user_token_security_logout(vendor_id, user?.token, toast);
        if (user_logout) {
            localStorage.removeItem('userData');
            localStorage.clear();
            dispatch(logout());
            navigate('/');
        }
    };

    const items = [
        { name: 'Dashboard', value: '/', icon: '', subitem: [] },
        { name: 'Users', value: '/users', icon: '', subitem: [] },
        { name: 'Agencies', value: '/agencies', icon: '', subitem: [] },
        { name: 'Profile', value: '/profile', icon: '', subitem: [] },
        { name: 'Languages', value: '/languages', icon: '', subitem: [] }
    ];

    const agencyitems = [
        { name: 'Dashboard', value: '/', icon: '', subitem: [] },
        { name: 'Staff', value: '/staff', subitem: [] },
        { name: 'Profile', value: '/profile', icon: '', subitem: [] },
        { name: 'Change Password', value: '/change-password', subitem: [] }
    ];

    const staffItems = [
        { name: 'Dashboard', value: '/', icon: '', subitem: [] },
        { name: 'Profile', value: '/profile', icon: '', subitem: [] },
        { name: 'Change Password', value: '/change-password', subitem: [] }
    ];

    const [menuItems, setMenuItems] = useState(user.role === "superadmin" ? items : user.role === "staff" ? staffItems : agencyitems);

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
    }, []);

    return (
        <div style={{ height: '100vh' }}>
            <Sidebar>
                <Menu>
                    {menuItems.map((item, index) => (
                        <MenuItem key={index} onClick={() => navigate(item.value)}>
                            {item.name}
                        </MenuItem>
                    ))}
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
            </Sidebar>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        vendor_id: state.auth.vendor_id,
    }
}
export default connect(mapStateToProps)(VendorSideBar);