import { GET_CITIES_LISTS, GET_HOTELS_LISTS, GET_TOP_HOTELS } from "./bookingTypes";


const initialState = {
    tophotels: [],
    searchedhotels: [],
    cities: []
};

export const bookingReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TOP_HOTELS:
            return {
                ...state,
                tophotels: action.payload,
            };
        case GET_HOTELS_LISTS:
            return {
                ...state,
                searchedhotels: action.payload,
            };
        case GET_CITIES_LISTS:
            return {
                ...state,
                cities: action.payload,
            };

        default:
            return state;
    }
};