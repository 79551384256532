import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Login from './Login';
import LoginIcon from '../assets/login-btn-icon.svg';
import signupIcon from '../assets/signup-btn-icon.svg';
import Signup from './Signup';
import { Router, useLocation, useNavigate } from 'react-router-dom';

const AuthPages = ({ user, vendor_id, isMobile }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const tabs = [
    { name: 'Login', image: LoginIcon },
    { name: 'Signup', image: signupIcon }
  ];

  return (
    <div className='login-signup-bg-wrapper'>
      <div className='login-signup-btn-grp'>
        {tabs.map(({ name, image }) => (
          <button
            type="button"
            key={name}
            className={`btn btn-default ${location.pathname.includes(name.toLowerCase()) ? "active" : ''}`}
            onClick={() => navigate('/' + name.toLowerCase())}
          >
            {/* Image added here */}
            <img src={image} alt={`${name}`} className="button-icon img-fluid" />
            {name}
          </button>
        ))}
      </div>
      {location.pathname === '/login' ? (
        <Login isMobile={isMobile} />
      ) : (
        <Signup />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    vendor_id: state.auth.vendor_id
  }
}
export default connect(mapStateToProps)(AuthPages);