

export const header_theme_design = (BgImg) => {
    try {
        return {
            backgroundImage: `url(${BgImg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'top'
        }
    } catch (error) {
        console.log("Error in Header Theme", error);
    }
};