import React from 'react'
import CarsCard from '../../shared/CarsCard';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import carsList from './carsData.json';
import { connect } from 'react-redux';

const TrendingCar = ({ currency }) => {
    const arr = [1, 2, 3, 4, 5];
    const { t } = useTranslation();

    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                },
            },
        ]
    };

    return (
        <div>
            <div className='trending-car-wrapper'>
                <div className='container'>
                    <h2 className='mb-3'>{t('trending_car')}</h2>
                    <div className='row w-100 mx-auto'>
                        <Slider {...settings}>
                            {carsList.map((item, index) =>
                                <div className='col-lg-4 col-md-4 col-sm-12' key={index}>
                                    <CarsCard item={item} />
                                </div>)}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        currency: state.auth.currency
    }
};

export default connect(mapStateToProps)(TrendingCar);