import React, { useEffect } from 'react'
import LocationCard from '../shared/LocationCard'
import TopHotels from './HomePages/TopHotels';
import TopCities from './HomePages/TopCities';
import Offers from './HomePages/Offers';
import TrendingCar from './HomePages/TrendingCar';
import Img1 from '../assets/deal.png';
import Img2 from '../assets/book-pay.png';
import Img3 from '../assets/verify.png';
import Img4 from '../assets/securepay.png';
import TravelImg from '../assets/where-to-travel.png';
import Contact from './HomePages/Contact';
import EmailSubscribe from './HomePages/EmailSubscribe';
import BookingFilter from './BookingFilter';
import { useLocation } from 'react-router-dom';

const HomePage = () => {

    const arr = [
        { heading: 'Search for Best Deals', title: 'Lorem Ipsum is simply dummy text of the printing', imgpath: Img1 },
        { heading: 'Book with Flexibility', title: 'Lorem Ipsum is simply dummy text of the printing', imgpath: Img2 },
        { heading: 'Best Price from Market', title: 'Lorem Ipsum is simply dummy text of the printing', imgpath: Img3 },
        { heading: 'Secure Payments', title: 'Lorem Ipsum is simply dummy text of the printing', imgpath: Img4 },
    ];
    const location = useLocation();

    return (
        <div className=''>
            {/* {(location.pathname !== "/signup" || location.pathname !== "/login") && <div className='booking-offers'>
                <BookingFilter />
            </div>} */}

            <div className='features-wrapper'>
                <div className='container'>
                    <div className='row'>
                        {arr.map((item, index) =>
                            <div className='col-lg-3 col-md-6 col-sm-6' key={index}>
                                <div className='inner-featues-box d-flex align-items-start gap-3'>
                                    <img src={item.imgpath} alt='' />
                                    <div className='right-features'>
                                        <h6 className='p-md mb-0'>{item.heading}</h6>
                                        <p className='p-sm'>{item.title}</p>
                                    </div>
                                </div>
                            </div>)}
                    </div>
                </div>
            </div>

            <div className='top-hotels position-relative'>
                <TopHotels />
            </div>

            <div className='top-cities'>
                <TopCities />
            </div>

            <div className='offers'>
                <Offers />
            </div>

            <div className='trending-cars section-padding'>
                <TrendingCar />
            </div>

            <div className='contact-with-travel'>
                <Contact />
            </div>

            <div className='email-subscribe'>
                <EmailSubscribe />
            </div>

        </div>
    )
}

export default HomePage