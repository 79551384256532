import { THEME_UPDATE } from "./themeTypes";

const initialState = {
    themeContext: 'normal'
};

const themeReducer = (state = initialState, action) => {
    switch (action.type) {
        case THEME_UPDATE:
            return {
                ...state,
                themeContext: action.payload,
            };
        default:
            return state;
    }
};

export default themeReducer;