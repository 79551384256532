import React from 'react'

const EmailSubscribe = () => {
    return (
        <div className='newsletter-wrapper section-padding'>
            <div className='container'>
                <div className='inner-newslwtter-wrapper'>
                    <div class="outer-shape">
                        <div class="custom-shape"></div>
                    </div>
                    <div className='inner-newsletter-form text-center'>
                        <h2 className='text-white'>Lorem Ipsum is simply dummy text of the printing</h2>
                        <p className='text-white mb-4 p-md'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        <form className='newsletter-form'>
                            <input type="email" id="site-search" placeholder='Enter Your Email' />
                            <button type="submit" class="newsletter-btn">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailSubscribe