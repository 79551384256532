import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { change_password, get_vender_id } from '../../reduxStore/User/userActions';
import { useToast } from '@chakra-ui/react';

const ChangePassword = ({ user, vendor_id }) => {
    const dispatch = useDispatch();
    const toast = useToast();
    const [formdata, setFormdata] = useState({ password: '', newpassword: '', confirmpassword: '' });
    const [errors, setErrors] = useState({ password: '', newpassword: '', confirmpassword: '' });
    const [showPass, setshowPass] = useState(false);
    const [newPass, setnewPass] = useState(false);
    const [confirmPass, setconfirmPass] = useState(false);

    useEffect(() => {
        get_vender_id("superadmin", dispatch);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'password':
                setErrors({ ...errors, password: value === '' ? 'Current Password is Required' : '' });
                setFormdata({ ...formdata, password: value.replace(/\s/g, "") });
                break;
            case 'newpassword':
                setErrors({ ...errors, newpassword: value === '' ? 'New Pasword is required' : value?.length < 6 ? 'Password length should be more than 5 characters' : value === formdata?.password ? 'New Password must be different from current password' : '' });
                setFormdata({ ...formdata, newpassword: value.replace(/\s/g, "") });
                break;
            case 'confirmpassword':
                setErrors({ ...errors, confirmpassword: value === '' ? 'Confirm Password is required' : value !== formdata?.newpassword ? 'Confirm Password doesnot match' : '' });
                setFormdata({ ...formdata, confirmpassword: value.replace(/\s/g, "") });
                break;

            default:
                break;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { password, newpassword, confirmpassword } = formdata;
        setErrors({
            password: !password ? "Password is required" : "",
            newpassword: !newpassword ? "New Password is required" : newpassword === password ? "New Password must be different from current password" : "",
            confirmpassword: !confirmpassword ? "Confirm Password is required" : confirmpassword !== newpassword ? 'Confirm Password doesnot match' : "",
        });

        if (password && password !== newpassword && newpassword === confirmpassword) {
            const userdata = { currentPassword: password, newPassword: newpassword };
            let passupdated = await change_password(userdata, user?.token, dispatch, vendor_id, toast);
            //  get_user_profile(dispatch, user);
            if (passupdated) {
                setFormdata({ password: '', newpassword: '', confirmpassword: '' });
                setErrors({ password: '', newpassword: '', confirmpassword: '' });
            }
        }
    };

    return (
        <div className="d-flex flex-column justify-content-center align-items-center">

            <div className="row align-items-center">
                <div className="col-12">
                    <h1 className="fw-bold text-black h3 mb-0">Change Password</h1>
                </div>
            </div>

            <div className="profile-deatils bg-white mt-4">
                <div className="p-4">
                    <form onSubmit={handleSubmit}>

                        <div className="mb-3">
                            <label className="text-dark fw-600 mb-1">Current Password</label>
                            <div className="password position-relative">
                                <input
                                    type={showPass ? 'text' : 'password'}
                                    name="password"
                                    className="form-control"
                                    placeholder="************"
                                    value={formdata?.password}
                                    onChange={handleChange}
                                    onKeyDown={(e) => e.key === " " && e.preventDefault()}
                                />
                                {!showPass ? (<i class="fas fa-eye-slash" onClick={() => setshowPass(!showPass)}></i>)
                                    : (<i class="fas fa-eye" onClick={() => setshowPass(!showPass)}></i>)}
                            </div>
                            {errors.password && <p className="error-msg text-danger">{errors.password}</p>}

                        </div>
                        <div className="mb-3">
                            <label className="text-dark fw-600 mb-1">New Password</label>
                            <div className="password position-relative">
                                <input
                                    type={newPass ? "text" : "password"}
                                    name="newpassword"
                                    className="form-control"
                                    placeholder="************"
                                    value={formdata?.newpassword}
                                    onChange={handleChange}
                                    onKeyDown={(e) => e.key === " " && e.preventDefault()}
                                />
                                {!newPass ? (<i class="fas fa-eye-slash" onClick={() => setnewPass(!newPass)}></i>)
                                    : (<i class="fas fa-eye" onClick={() => setnewPass(!newPass)}></i>)}
                            </div>
                            {errors.newpassword && <p className="error-msg text-danger">{errors.newpassword}</p>}

                        </div>
                        <div className="mb-3">
                            <label className="text-dark fw-600 mb-1">Confirm Password</label>
                            <div className="password position-relative">
                                <input
                                    type={confirmPass ? 'text' : 'password'}
                                    name="confirmpassword"
                                    className="form-control"
                                    placeholder="************"
                                    value={formdata?.confirmpassword}
                                    onChange={handleChange}
                                    onKeyDown={(e) => e.key === " " && e.preventDefault()}
                                />
                                {!confirmPass ? (<i class="fas fa-eye-slash" onClick={() => setconfirmPass(!confirmPass)}></i>)
                                    : (<i class="fas fa-eye" onClick={() => setconfirmPass(!confirmPass)}></i>)}
                            </div>
                            {errors.confirmpassword && <p className="error-msg text-danger">{errors.confirmpassword}</p>}

                        </div>

                        <button type="submit" className="ylw-theme-button">
                            Change Password
                        </button>
                    </form>
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        userDetails: state.auth.userDetails,
        vendor_id: state.auth.vendor_id
    }
}
export default connect(mapStateToProps)(ChangePassword);