import React, { useState } from 'react'
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import SelectPopOver from '../../shared/SelectPopOver';
import { useToast } from '@chakra-ui/react';
import { connect } from 'react-redux';

const HotelBar = ({ user }) => {
    const navigate = useNavigate();
    const toast = useToast();
    const [searchVal, setSearchVal] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [optionsData, setOptionsData] = useState({
        Room: 1,
        Adults: 1,
        Children: 1,
        Pets: 1
    });

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleSearch = async () => {
        if (searchVal === '') {
            toast({ title: 'Please enter a destination to start searching.', status: "error", duration: 2000, position: "top-right", isClosable: true, });
            return;
        }

        if (startDate === null || endDate === null) {
            toast({ title: 'Please Select Checkin - Checkout Date.', status: "error", duration: 2000, position: "top-right", isClosable: true, });
            return;
        }
        navigate('/hotel-results');
    };

    return (
        <form>
            <div class="booking-form-grid">
                <div className="destination-box first-box item-one">
                    <img src="./images/geo-alt.png" className='d-inline-block' alt='' />
                    <input type="text" className="form-control" value={searchVal} placeholder="Search Destination" onChange={(e) => setSearchVal(e.target.value)} />
                </div>
                <div className="destination-box second-box marrgin-left item-two">
                    {/* <input type="date" className="form-control"></input> */}
                    <DatePicker
                        className='form-control'
                        showIcon
                        selected={startDate}
                        onChange={(update) => setDateRange(update)}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        minDate={new Date()}
                        placeholderText='mm/dd/yyyy - mm/dd/yyyy'
                        isClearable
                    />
                </div>
                <div className="destination-box  d-flex justify-content-center align-items-center first-box item-four">
                    <img src="./images/people-outline.png" alt='' />
                    {/* <select class="form-select" aria-label="Default select example">
                                                    <option selected>Open this select menu</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select> */}
                    <input
                        type="text"
                        value={`${optionsData.Adults} Adult, ${optionsData.Children} Children, ${optionsData.Pets} Pets and ${optionsData.Room} Room`}
                        className="form-control"
                        placeholder="2 Adult, 1 Children"
                        onClick={handleClick}
                    />
                </div>
                {open && <SelectPopOver
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    setAnchorEl={() => setAnchorEl(null)}
                    optionsData={optionsData}
                    setOptionsData={setOptionsData}
                />}
                <div className="destination-box search-btn item-five">
                    <button type="button" className="btn btn-gradient-purple text-white d-flex align-items-center justify-content-center gap-2" onClick={handleSearch}><i class="fas fa-search me-2"></i>Search</button>
                </div>
            </div>
        </form>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    }
};
export default connect(mapStateToProps)(HotelBar);